export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const USER_UNLOADED = "USER_UNLOADED";
export const USER_ERROR = "USER_ERROR";

export const PROFILE_LOADING = "PROFILE_LOADING";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_FAIL = "PROFILE_FAIL";

export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const ALL_USERS_SUCCESS = "ALL_USERS_SUCCESS";
export const ALL_USERS_FAIL = "ALL_USERS_FAIL";

export const ALL_DEVELOPERS_SUCCESS = "ALL_DEVELOPERS_SUCCESS";
export const ALL_DEVELOPERS_FAIL = "ALL_DEVELOPERS_FAIL";

export const ALL_MESSAGES_SUCCESS = "ALL_MESSAGES_SUCCESS";
export const ALL_MESSAGES_FAIL = "ALL_MESSAGES_FAIL";
export const ADD_NEW_MESSAGE = "ADD_NEW_MESSAGE";
export const ADD_NEW_SUB_MESSAGE = "ADD_NEW_SUB_MESSAGE";

export const ALL_LICENSES_SUCCESS = "ALL_LICENSES_SUCCESS";
export const ALL_LICENSES_FAIL = "ALL_LICENSES_FAIL";
export const ADD_NEW_LICENSE = "ADD_NEW_LICENSE";

export const VISITS_SUCCESS = "VISITS_SUCCESS";
export const VISITS_FAIL = "VISITS_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
