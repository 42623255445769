import axios from 'axios';
import { notification } from 'antd';
import { getServerURL } from '../constants/server';
import {
    ADMIN_WALLET_SUCCESS,
    ADMIN_WALLET_CURRENCIES_SUCCESS,
} from '../constants/financeConstants';

export const getAdminWallet = () => async (dispatch) => {
    try {
        const { data } = await axios.get(`${getServerURL()}/api/v1/finance/admin-wallet`, {
            headers: {
                'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        });
        dispatch({
            type: ADMIN_WALLET_SUCCESS,
            payload: {
                wallet: data.wallet,
                ETH: data.ETH,
                BNB: data.BNB,
                tBNB: data.tBNB,
            }
        });
    }
    catch (error) {
        console.log(error);
    }
};

export const getAdminWalletCurrencies = (chainId) => async (dispatch) => {
    try {
        const { data } = await axios.get(`${getServerURL()}/api/v1/finance/admin-wallet-currencies/${chainId}`, {
            headers: {
                'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        });
        dispatch({
            type: ADMIN_WALLET_CURRENCIES_SUCCESS,
            payload: {
                chainId: chainId,
                currencies: data.currencies,
            }
        });
    }
    catch (error) {
        console.log(error);
    }
};

export const withdrawFromBot = (botId) => async (dispatch) => {
    try {
        await axios.post(`${getServerURL()}/api/v1/finance/bot/withdraw`, 
            {
                botId
            },
            {
                headers: {
                    'Authorization': `bearer ${localStorage.getItem('token')}`
                }
            }
        );

        notification.success({
            message: 'Finance',
            description: 'Withdraw success',
        });
    }
    catch (error) {
        notification.warning({
            message: 'Finance',
            description: 'Failed to withdraw from the bot',
        });
        console.log(error);
    }
};

export const withdrawFromAllBots = () => async (dispatch) => {
    try {
        await axios.get(`${getServerURL()}/api/v1/finance/bot/withdraw-all`,
            {
                headers: {
                    'Authorization': `bearer ${localStorage.getItem('token')}`
                }
            }
        );

        notification.success({
            message: 'Finance',
            description: 'Withdraw All success',
        });
    }
    catch (error) {
        notification.warning({
            message: 'Finance',
            description: 'Failed to withdraw from the bot',
        });
        console.log(error);
    }
};
