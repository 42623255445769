import {
    Row,
    Col,
    Card,
    Table,
    Avatar,
    Typography,
} from "antd";
import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getAllUsers } from '../actions/userAction';
import { getAllBots } from '../actions/botAction';
import { RPC_URLS } from '../constants/server';

import bscTokens from "../config/bsc_token_list.json";
import ethTokens from "../config/eth_token_list.json";
import bscTestnetTokens from "../config/bsc_testnet_token_list.json";

const { Title } = Typography;

const rewardTableFields = [
    {
        title: "BOT",
        dataIndex: "bot",
        key: "bot",
        // width: "32%",
    },
    {
        title: "CHAIN",
        dataIndex: "chain",
        key: "chain",
    },
    {
        title: "CURRENCY",
        dataIndex: "currency",
        key: "currency",
    },
    {
        title: "REWARD",
        dataIndex: "reward",
        key: "reward",
    },
    {
        title: "DEPOSITED",
        dataIndex: "deposited",
        key: "deposited",
    },
    {
        title: "TOTAL",
        dataIndex: "total",
        key: "total",
    },
];

function UserDetail() {
    const dispatch = useDispatch();
    const { userId } = useParams();
    const { users } = useSelector(state => state.users);
    const { bots } = useSelector(state => state.bots);
    const { rewards } = useSelector(state => state.userRewards);
    const user = users.find(item => item._id === userId);
    const reward = rewards?.find(item => item.userId === userId);

    useEffect(() => {
        dispatch(getAllUsers());
        dispatch(getAllBots());
    }, [dispatch]);

    const rewardTableData = reward?.bots.map((item, index) => {
        const bot = bots.find(bot => bot._id === item.botId);
        let logoURI = "";
        if (item.chainId === "1") {
            const token = ethTokens.find(token => token.address.toUpperCase() === item.currency.toUpperCase());
            logoURI = token?.logoURI;
        }
        else if (item.chainId === "56") {
            const token = bscTokens.find(token => token.address.toUpperCase() === item.currency.toUpperCase());
            logoURI = token?.logoURI;
        }
        else if (item.chainId === "97") {
            const token = bscTestnetTokens.find(token => token.address.toUpperCase() === item.currency.toUpperCase());
            logoURI = token?.logoURI;
        }

        return {
            key: index,
            bot: (
                <div className="author-role">
                    <Title level={5}>{bot ? bot.name : "[Deleted Bot]"}</Title>
                </div>
            ),
            chain: (
                <div className="author-role">
                    <Title level={5}>{item.chainId ? RPC_URLS[item.chainId].description : "Unknown"}</Title>
                </div>
            ),
            currency: (
                <>
                    <Avatar.Group>
                        <Avatar
                            className="shape-avatar"
                            shape="square"
                            size={40}
                            src={logoURI} />
                        <div className="avatar-info">
                            <Title level={5}>{item.currencySymbol}</Title>
                        </div>
                    </Avatar.Group>{" "}
                </>
            ),
            deposited: (
                <>
                    <div className="author-role">
                        <Title level={5}>{item.deposited}</Title>
                    </div>
                </>
            ),
            reward: (
                <>
                    <div className="author-role">
                        <Title level={5}>{item.reward}</Title>
                    </div>
                </>
            ),
            total: (
                <>
                    <div className="author-role">
                        <Title level={5}>{item.balance}</Title>
                    </div>
                </>
            ),
        };
    });

    return (
        <>
            <div className="tabled">
                <Row className="rowgap-vbox" gutter={[24, 0]}>
                    <Col xs={24} xl={24}>
                        <Card bordered={false} className="criclebox mb-24">
                            <div className="number">
                                <Row align="middle" gutter={[24, 0]}>
                                    <Col xs={24}>
                                        <span>User Info</span>
                                        <Title level={3}>
                                            {user ? user.name : "[Deleted User]"} <small className="bnb2">{user?.email}</small>
                                        </Title>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col xs={24} xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            title="User Rewards">
                            <div className="table-responsive">
                                <Table
                                    columns={rewardTableFields}
                                    dataSource={rewardTableData}
                                    pagination={true}
                                    className="ant-border-space" />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default UserDetail;
