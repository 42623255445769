import {
    ALL_BOTS_SUCCESS,
    ALL_BOTS_FAIL,
    NEW_BOT_SUCCESS,
    NEW_BOT_FAIL,
    DELETE_BOT_SUCCESS,
    DELETE_BOT_FAIL,
    START_BOT_SUCCESS,
    START_BOT_FAIL,
    STOP_BOT_SUCCESS,
    STOP_BOT_FAIL,
    ADD_BOT_LOG,
    CLEAR_BOT_LOG,
    ALL_BOT_TRADES_SUCCESS,
    ALL_BOT_TRADES_FAIL,
    ADD_BOT_TRADE,
    UPDATE_BOT_SUCCESS,
    UPDATE_BOT_FAIL,
} from '../constants/botConstants';
import { USER_UNLOADED } from '../constants/userConstants';

export const allBotsReducer = (state = { bots: [] }, { type, payload }) => {
    switch (type) {
        case ALL_BOTS_SUCCESS:
        case NEW_BOT_SUCCESS:
        case UPDATE_BOT_SUCCESS:
        case DELETE_BOT_SUCCESS:
            return {
                ...state,
                bots: payload,
            };
        case ALL_BOTS_FAIL:
        case NEW_BOT_FAIL:
        case UPDATE_BOT_FAIL:
        case DELETE_BOT_FAIL:
        case START_BOT_FAIL:
        case STOP_BOT_FAIL:
            return {
                ...state,
                error: payload,
                bots: [],
            };
        
        case START_BOT_SUCCESS:
        case STOP_BOT_SUCCESS:
            let bot = state.bots.find(item => item._id === payload);
            if (bot)
                bot.status = (type === START_BOT_SUCCESS) ? "started" : "stopped";
            return {
                bots: [
                    ...state.bots
                ]
            };

        default:
            return state;
    }
};

export const botLogsReducer = (state = { logs: [] }, { type, payload }) => {
    switch (type) {
        case ADD_BOT_LOG:
            return {
                ...state,
                logs: [
                    payload,
                    ...state.logs
                ]
            };

        case CLEAR_BOT_LOG:
            return {
                ...state,
                logs: []
            };

        default:
            return state;
    }
};

export const allBotTradesReducer = (state = { trades: [] }, { type, payload }) => {
    switch (type) {
        case ALL_BOT_TRADES_SUCCESS:
            return {
                trades: payload
            };

        case ALL_BOT_TRADES_FAIL:
            return {
                error: payload
            };

        case ADD_BOT_TRADE:
            return {
                trades: [
                    ...state.trades,
                    payload
                ]
            };

        case USER_UNLOADED:
            return {
                trades: []
            };

        default:
            return state;
    }
};
