import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    Upload,
    message,
    Modal,
    Form,
    Input,
    Select,
    Button,
    Avatar,
    Typography,
} from "antd";
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import ReactApexChart from "react-apexcharts";
import { ToTopOutlined, InboxOutlined, ClearOutlined } from "@ant-design/icons";
import { getServerURL, RPC_URLS } from "../constants/server";
import { getAllBots, addNewBot, deleteBot, startBot, stopBot, updateBot } from "../actions/botAction";
import { CLEAR_BOT_LOG } from "../constants/botConstants";

// Images
import defaultAvatar from "../assets/images/logo-jira.svg";

const { Title } = Typography;

// table code start
const botTableFields = [
    {
        title: "NAME",
        dataIndex: "name",
        key: "name",
        width: "15%",
    },
    {
        title: "DESCRIPTION",
        dataIndex: "description",
        key: "description",
        width: "22%",
    },
    {
        title: "PATH",
        key: "path",
        dataIndex: "path",
    },
    {
        title: "CREATED AT",
        key: "created",
        dataIndex: "created",
    },
    {
        title: "STATUS",
        key: "status",
        dataIndex: "status",
    },
    {
        title: "ACTION",
        key: "action",
        dataIndex: "action",
    },
];

const tradingTableFields = [
    {
        title: "TIME",
        key: "time",
        dataIndex: "time",
        width: "15%",
    },
    {
        title: "BOT",
        key: "bot",
        dataIndex: "bot",
        width: "15%",
    },
    {
        title: "CHAIN",
        key: "chain",
        dataIndex: "chain",
        // width: "15%",
    },
    {
        title: "PROFIT",
        key: "profit",
        dataIndex: "profit",
        width: "10%",
    },
    {
        title: "TXN FEE",
        key: "txnFee",
        dataIndex: "txnFee",
        width: "10%",
    },
    {
        title: "TXN",
        key: "txn",
        dataIndex: "txn",
    },
];

const logTableFields = [
    {
        title: "TIME",
        key: "time",
        dataIndex: "time",
        width: "15%",
    },
    {
        title: "BOT",
        key: "bot",
        dataIndex: "bot",
        width: "15%",
    },
    {
        title: "LEVEL",
        key: "level",
        dataIndex: "level",
        width: "10%",
    },
    {
        title: "MESSAGE",
        key: "message",
        dataIndex: "message",
    },
];

function Bots() {
    const { bots } = useSelector(state => state.bots);
    const { logs } = useSelector(state => state.botLogs);
    const { trades } = useSelector(state => state.botTrades);
    const [ botViewMode, setBotViewMode ] = useState("bot_all");
    const [ logViewMode, setLogViewMode ] = useState("log_info");
    const [ profitViewMode, setProfitViewMode ] = useState("profit_1D");
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllBots());
    }, [dispatch]);

    const [selectedBot, setSelectedBot] = useState(null);
    const [isOpenDeleteBot, setIsOpenDeleteBot] = useState(false);
    const [isOpenStartBot, setIsOpenStartBot] = useState(false);
    const onHandleDeleteBot = () => {
        if (selectedBot) {
            dispatch(deleteBot(selectedBot._id));
            setSelectedBot(null);
        }
        setIsOpenDeleteBot(false);
    };
    const onCancelDeleteBot = () => {
        setIsOpenDeleteBot(false);
    }
    const onHandleStartBot = () => {
        if (selectedBot) {
            if (selectedBot.status === "started")
                dispatch(stopBot(selectedBot._id));
            else
                dispatch(startBot(selectedBot._id));
            setSelectedBot(null);
        }
        setIsOpenStartBot(false);
    };
    const onCancelStartBot = () => {
        setIsOpenStartBot(false);
    }

    const onChangeBotViewMode = (e) => {
        setBotViewMode(e.target.value);
    };
    
    const onDeleteBot = (bot) => {
        setSelectedBot(bot);
        setIsOpenDeleteBot(true);
    };
    const onStartBot = (bot) => {
        setSelectedBot(bot);
        setIsOpenStartBot(true);
    };

    const onChangeLogViewMode = (e) => {
        setLogViewMode(e.target.value);
    };

    const getBotNameById = (id) => {
        const searched = bots?.find(item => item._id === id);
        if (searched)
            return searched.name;
        return "";
    };
    
    const botList = bots?.filter(item => {
        if (botViewMode === "bot_running")
            return item.status === "started";
        if (botViewMode === "bot_stopped")
            return item.status === "stopped";
        return true;
    });

    const botTableData = botList?.map((bot, index) => {
        return {
            key: index,
            name: (
                <>
                    <Avatar.Group>
                        <Avatar
                            className="shape-avatar"
                            shape="square"
                            size={40}
                            src={defaultAvatar} />
                        <div className="avatar-info">
                            <Title level={5}>{bot.name}</Title>
                            <p>{bot.type}</p>
                        </div>
                    </Avatar.Group>{" "}
                </>
            ),
            description: (
                <>
                    <div className="author-desc">
                        <span>{bot.description}</span>
                    </div>
                </>
            ),
            path: (
                <>
                    <div className="author-path">
                        <span>{bot.path}</span>
                    </div>
                </>
            ),
            status: (
                <>
                    <div className="ant-status">
                        <span>{bot.status === "started" ? "RUNNING" : "STOPPED"}</span>
                    </div>
                </>
            ),
            created: (
                <>
                    <div className="ant-created">
                        <span>{new Date(bot.createdAt).toLocaleString()}</span>
                    </div>
                </>
            ),
            action: (
                <>
                    <div className="ant-action">
                        <Button.Group>
                            <Button onClick={ () => onStartBot(bot) }>{bot.status === "started" ? "Stop" : "Start"}</Button>
                            <Button onClick={ () => onUpdateBot(bot) }>Update</Button>
                            <Button onClick={ () => onDeleteBot(bot) }>Delete</Button>
                        </Button.Group>
                    </div>
                </>
            )
        };
    });

    const reversedTrades = trades ? [ ...trades ].reverse() : [];
    const tradingTableData = reversedTrades.map((item, index) => {
        return {
            key: index,
            time: (
                <>
                    <div className="author-role">
                        <Title level={5}>{new Date(item.executedAt).toLocaleString()}</Title>
                    </div>
                </>
            ),
            bot: (
                <>
                    <div className="author-role">
                        <Title level={5}>{getBotNameById(item.botId)}</Title>
                    </div>
                </>
            ),
            chain: (
                <>
                    <div className="author-role">
                        <Title level={5}>{item.chainId ? RPC_URLS[item.chainId].description : "Unknown"}</Title>
                    </div>
                </>
            ),
            profit: (
                <>
                    <div className="author-role">
                        <Title level={5}>{item.profit}</Title>
                        <a href={`${item.chainId ? RPC_URLS[item.chainId].explorer : ""}/token/${item.currency}#code`} className="text-sm" target="_blank" rel="noreferrer">{item.currencySymbol}</a>
                    </div>
                </>
            ),
            txnFee: (
                <>
                    <div className="author-role">
                        <Title level={5}>{item.txnFee}</Title>
                    </div>
                </>
            ),
            txn: (
                <>
                    <div className="author-role">
                        <a href={`${item.chainId ? RPC_URLS[item.chainId].explorer : ""}/tx/${item.txn}`} className="text-base" target="_blank" rel="noreferrer">{item.txn}</a>
                    </div>
                </>
            ),
        };
    });

    const logList = logs.filter(item => {
        if (logViewMode === "log_error")
            return item.level === "error";
        if (logViewMode === "log_info")
            return item.level === "info";
        if (logViewMode === "log_debug")
            return item.level === "debug";
        return true;
    });
    
    const logTableData = logList.map((log, index) => {
        return {
            key: index,
            time: (
                <>
                    <div className={`log-${log.level}`}>
                        <span>{new Date(log.time).toLocaleString()}</span>
                    </div>
                </>
            ),
            bot: (
                <>
                    <div className={`log-${log.level}`}>
                        <span>{getBotNameById(log.sender)}</span>
                    </div>
                </>
            ),
            level: (
                <>
                    <div className={`log-${log.level}`}>
                        <span>{log.level}</span>
                    </div>
                </>
            ),
            message: (
                <>
                    <div className={`log-${log.level}`}>
                        <span>{log.message}</span>
                    </div>
                </>
            ),
        };
    });

    const [newBotForm] = Form.useForm();
    const [isOpenNewBot, setIsOpenNewBot] = useState(false);
    const showNewBotModal = () => {
        newBotForm.resetFields();
        setIsOpenNewBot(true);
    }
    const onHandleAddBot = async () => {
        const valid = await newBotForm.validateFields();
        if (valid.errorFields)
            return;
        
        const name = newBotForm.getFieldValue("name");
        const description = newBotForm.getFieldValue("description");
        const type = newBotForm.getFieldValue("type") || "defi";
        const path = newBotForm.getFieldValue("image").file.response.filename;
        dispatch(addNewBot({ name, description, type, path }));
        setIsOpenNewBot(false);
    };
    const onCancelAddBot = () => {
        setIsOpenNewBot(false);
    }

    const [updateBotForm] = Form.useForm();
    const [isOpenUpdateBot, setIsOpenUpdateBot] = useState(false);
    const onUpdateBot = (bot) => {
        setSelectedBot(bot);
        setIsOpenUpdateBot(true);

        updateBotForm.resetFields();
        updateBotForm.setFieldValue("name", bot.name);
        updateBotForm.setFieldValue("description", bot.description);
        updateBotForm.setFieldValue("type", bot.type);
    };
    const onHandleUpdateBot = async () => {
        const valid = await updateBotForm.validateFields();
        if (valid.errorFields)
            return;
        
        const name = updateBotForm.getFieldValue("name");
        const description = updateBotForm.getFieldValue("description");
        const type = updateBotForm.getFieldValue("type") || "defi";
        const image = updateBotForm.getFieldValue("image");
        const path = image?.file.response.filename;
        dispatch(updateBot(selectedBot._id, { name, description, type, path }));
        setIsOpenUpdateBot(false);
    };
    const onCancelUpdateBot = () => {
        setIsOpenUpdateBot(false);
    }

    const { Dragger } = Upload;

    const uploadProps = {
        name: 'file',
        multiple: false,
        action: `${getServerURL()}/api/v1/bots/upload`,
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                console.log(info.file.response);
                message.success(`${info.file.name} file uploaded successfully.`);
            }
            else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const clearLogs = () => {
        dispatch({
            type: CLEAR_BOT_LOG,
        });
    };

    const onChangeProfitViewMode = (e) => {
        setProfitViewMode(e.target.value);
    };

    let profitChartData = {
        series: [],
        options: {
            chart: {
                width: "100%",
                height: 350,
                type: "area",
                toolbar: {
                    show: false,
                },
            },
    
            legend: {
                show: true,
            },
    
            dataLabels: {
                enabled: false,
            },

            stroke: {
                curve: "smooth",
            },
    
            yaxis: {
                labels: {
                    style: {
                        fontSize: "14px",
                        fontWeight: 600,
                        colors: ["#8c8c8c"],
                    },
                },
            },
    
            xaxis: {
                labels: {
                    style: {
                        fontSize: "14px",
                        fontWeight: 600,
                        colors: [
                            "#8c8c8c",
                            "#8c8c8c",
                            "#8c8c8c",
                            "#8c8c8c",
                            "#8c8c8c",
                            "#8c8c8c",
                            "#8c8c8c",
                            "#8c8c8c",
                            "#8c8c8c",
                        ],
                    },
                },
                // categories: [
                //     "1",
                //     "2",
                //     "3",
                //     "4",
                //     "5",
                //     "6",
                //     "7",
                //     "8",
                //     "9",
                // ],
            },
    
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val;
                    },
                },
            },
        },
    };

    const now = new Date();
    let filteredTrades = {};
    let data = {};
    let categories = [];
    for (let i = 0; i < bots?.length; i++) {
        filteredTrades[bots[i]._id] = trades?.filter((item) => item.botId === bots[i]._id);
        data[i] = [];
    }
    
    if (profitViewMode === "profit_1D") {
        const now2 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours());
        const nowTime = now2.getTime();
        for (let h = 23; h >= 0; h--) {
            const from = new Date(nowTime - 3600000 * h);
            const to = new Date(from.getTime() + 3600000);
            for (let i = 0; i < bots?.length; i++) {
                const t = filteredTrades[bots[i]._id];
                let profit = 0;
                for (let j = 0; j < t?.length; j++) {
                    const d = new Date(t[j].executedAt);
                    if (from <= d && d < to)
                        profit += t[j].profit;
                }

                data[i] = [
                    ...data[i],
                    profit
                ];
            }
            categories = [
                ...categories,
                `${from.getHours()}h`
            ];
        }
    }
    else if (profitViewMode === "profit_7D") {
        const now2 = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const nowTime = now2.getTime();
        for (let d = 6; d >= 0; d--) {
            const from = new Date(nowTime - 86400000 * d);
            const to = new Date(from.getTime() + 86400000);
            for (let i = 0; i < bots?.length; i++) {
                const t = filteredTrades[bots[i]._id];
                let profit = 0;
                for (let j = 0; j < t?.length; j++) {
                    const d = new Date(t[j].executedAt);
                    if (from <= d && d < to)
                        profit += t[j].profit;
                }
                data[i] = [
                    ...data[i],
                    profit
                ];
            }

            categories = [
                ...categories,
                `${from.getMonth() + 1}/${from.getDate()}`
            ];
        }
    }
    else if (profitViewMode === "profit_1M") {
        const now2 = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const nowTime = now2.getTime();
        for (let d = 29; d >= 0; d--) {
            const from = new Date(nowTime - 86400000 * d);
            const to = new Date(from.getTime() + 86400000);
            for (let i = 0; i < bots?.length; i++) {
                const t = filteredTrades[bots[i]._id];
                let profit = 0;
                for (let j = 0; j < t?.length; j++) {
                    const d = new Date(t[j].executedAt);
                    if (from <= d && d < to)
                        profit += t[j].profit;
                }
                data[i] = [
                    ...data[i],
                    profit
                ];
            }

            categories = [
                ...categories,
                `${from.getMonth() + 1}/${from.getDate()}`
            ];
        }
    }
    else if (profitViewMode === "profit_1Y") {
        for (let m = 11; m >= 0; m--) {
            const from = (now.getMonth() < m) ? new Date(now.getFullYear() - 1, now.getMonth() + 12 - m): new Date(now.getFullYear(), now.getMonth() - m);
            const to = (from.getMonth() === 11) ? new Date(from.getFullYear() + 1, 0): new Date(from.getFullYear(), from.getMonth() + 1);
            for (let i = 0; i < bots?.length; i++) {
                const t = filteredTrades[bots[i]._id];
                let profit = 0;
                for (let j = 0; j < t?.length; j++) {
                    const d = new Date(t[j].executedAt);
                    if (from <= d && d < to)
                        profit += t[j].profit;
                }
                data[i] = [
                    ...data[i],
                    profit
                ];
            }

            categories = [
                ...categories,
                `${from.getFullYear()}/${from.getMonth() + 1}`
            ];
        }
    }

    for (let i = 0; i < bots?.length; i++) {
        profitChartData.series = [
            ...profitChartData.series,
            {
                name: bots[i].name,
                data: data[i].map(v => Number(v).toFixed(2)),
                offsetY: 0
            }
        ];
    }
    
    profitChartData.options.xaxis.categories = categories;

    return (
        <>
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs={24} xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            title="Bot List"
                            extra={
                                <>
                                    <Radio.Group onChange={onChangeBotViewMode} defaultValue="bot_all">
                                        <Radio.Button value="bot_all">All</Radio.Button>
                                        <Radio.Button value="bot_running">RUNNING</Radio.Button>
                                        <Radio.Button value="bot_stopped">STOPPED</Radio.Button>
                                    </Radio.Group>
                                </>
                            }>
                            <div className="table-responsive">
                                <Table
                                    columns={botTableFields}
                                    dataSource={botTableData}
                                    pagination={false}
                                    className="ant-border-space" />
                            </div>
                            <div className="uploadfile pb-15 shadow-none">
                                <Button
                                    type="dashed"
                                    className="ant-full-box"
                                    icon={<ToTopOutlined />}
                                    onClick={showNewBotModal}>
                                    Add New Bot...
                                </Button>
                            </div>
                            <Modal title="Delete Bot" open={isOpenDeleteBot} onOk={onHandleDeleteBot} onCancel={onCancelDeleteBot}>
                                <p>Are you sure you want to delete bot '{selectedBot ? selectedBot.name : ''}'?</p>
                            </Modal>
                            <Modal title={(selectedBot && selectedBot.status === "started") ? "Stop Bot" : "Start Bot"} open={isOpenStartBot} onOk={onHandleStartBot} onCancel={onCancelStartBot}>
                                <p>Are you sure you want to {(selectedBot && selectedBot.status === "started") ? "stop" : "start"} bot '{selectedBot ? selectedBot.name : ''}'?</p>
                            </Modal>
                            <Modal title="Update Bot" open={isOpenUpdateBot} onOk={onHandleUpdateBot} onCancel={onCancelUpdateBot}>
                                <Form
                                    form={updateBotForm}
                                    layout="vertical"
                                    className="row-col">
                                    <Form.Item
                                        className="username"
                                        label="Name"
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please input bot name!",
                                            },
                                        ]}>
                                        <Input placeholder="Type your bot name" />
                                    </Form.Item>

                                    <Form.Item
                                        className="username"
                                        label="Description"
                                        name="description">
                                        <Input placeholder="Type your bot description" />
                                    </Form.Item>

                                    <Form.Item
                                        className="username"
                                        label="Type"
                                        name="type">
                                        <Select
                                            defaultValue="defi"
                                            style={{ width: '100%' }}
                                            options={[
                                                { value: 'defi', label: 'DeFi' },
                                                { value: 'cefi', label: 'CeFi' },
                                            ]} />
                                    </Form.Item>
                                    <Form.Item
                                        className="username"
                                        label="Image"
                                        name="image">
                                        <Dragger {...uploadProps}>
                                            <p className="ant-upload-drag-icon">
                                                <InboxOutlined />
                                            </p>
                                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                            <p className="ant-upload-hint">
                                                Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                                                banned files.
                                            </p>
                                        </Dragger>
                                    </Form.Item>
                                </Form>
                            </Modal>
                            <Modal title="Add New Bot" open={isOpenNewBot} onOk={onHandleAddBot} onCancel={onCancelAddBot}>
                                <Form
                                    form={newBotForm}
                                    layout="vertical"
                                    className="row-col">
                                    <Form.Item
                                        className="username"
                                        label="Name"
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please input bot name!",
                                            },
                                        ]}>
                                        <Input placeholder="Type your bot name" />
                                    </Form.Item>

                                    <Form.Item
                                        className="username"
                                        label="Description"
                                        name="description">
                                        <Input placeholder="Type your bot description" />
                                    </Form.Item>

                                    <Form.Item
                                        className="username"
                                        label="Type"
                                        name="type">
                                        <Select
                                            defaultValue="defi"
                                            style={{ width: '100%' }}
                                            options={[
                                                { value: 'defi', label: 'DeFi' },
                                                { value: 'cefi', label: 'CeFi' },
                                            ]} />
                                    </Form.Item>
                                    <Form.Item
                                        className="username"
                                        label="Image"
                                        name="image"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select image!",
                                            },
                                        ]}>
                                        <Dragger {...uploadProps}>
                                            <p className="ant-upload-drag-icon">
                                                <InboxOutlined />
                                            </p>
                                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                            <p className="ant-upload-hint">
                                                Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                                                banned files.
                                            </p>
                                        </Dragger>
                                    </Form.Item>
                                </Form>
                            </Modal>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col xs={24} xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            title="Profits by Bot"
                            extra={
                                <>
                                    <Radio.Group onChange={onChangeProfitViewMode} defaultValue="profit_1D">
                                        <Radio.Button value="profit_1D">1D</Radio.Button>
                                        <Radio.Button value="profit_7D">7D</Radio.Button>
                                        <Radio.Button value="profit_1M">1M</Radio.Button>
                                        <Radio.Button value="profit_1Y">1Y</Radio.Button>
                                    </Radio.Group>
                                </>
                            }>
                            <ReactApexChart
                                className="full-width ml-24 mr-24 mt-24 mb-24"
                                options={profitChartData.options}
                                series={profitChartData.series}
                                type="area"
                                height={350}
                                width={"100%"} />
                        </Card>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col xs={24} xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            title="Trading History">
                            <div className="table-responsive">
                                <Table
                                    columns={tradingTableFields}
                                    dataSource={tradingTableData}
                                    pagination={true}
                                    className="ant-border-space" />
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col xs={24} xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            title="Bot Activity Logs"
                            extra={
                                <>
                                    <Radio.Group onChange={onChangeLogViewMode} defaultValue="log_info">
                                        <Radio.Button value="log_all">All</Radio.Button>
                                        <Radio.Button value="log_error">Error</Radio.Button>
                                        <Radio.Button value="log_info">Info</Radio.Button>
                                        <Radio.Button value="log_debug">Debug</Radio.Button>
                                    </Radio.Group>
                                    <Button
                                        style={{ marginLeft: '10px' }}
                                        icon={<ClearOutlined />}
                                        onClick={clearLogs}>
                                        Clear
                                    </Button>
                                </>
                            }>
                            <div className="table-responsive">
                                <Table
                                    columns={logTableFields}
                                    dataSource={logTableData}
                                    pagination={true}
                                    className="ant-border-space" />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Bots;
