import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const { loading, user } = useSelector(state => state.user);
    // console.log("ProtectedRoute: loading=", loading, " ", " user=", user);
    return (
        <>
            {
                (loading === false) && (!user || user.status !== "verified") ? <Redirect to="/sign-in" /> : children
            }
        </>
    );
};

export default ProtectedRoute;
