import {
    ALL_USER_TRANSACTIONS_SUCCESS,
    ALL_USER_TRANSACTIONS_FAIL,
    ADD_USER_TRANSACTION,
    ADMIN_WALLET_SUCCESS,
    ADMIN_WALLET_FAIL,
    ALL_BOT_TRANSACTIONS_SUCCESS,
    ALL_BOT_TRANSACTIONS_FAIL,
    ADD_BOT_TRANSACTION,
    ALL_BOT_BALANCES_SUCCESS,
    ALL_BOT_BALANCES_FAIL,
    ALL_USER_REWARDS_SUCCESS,
    ALL_USER_REWARDS_FAIL,
    ADMIN_WALLET_CURRENCIES_SUCCESS,
} from "../constants/financeConstants";
import { USER_UNLOADED } from "../constants/userConstants";

export const allUserTransactionsReducer = (state = { transactions: [] }, { type, payload }) => {
    switch (type) {
        case ALL_USER_TRANSACTIONS_SUCCESS:
            return {
                transactions: payload
            };

        case ALL_USER_TRANSACTIONS_FAIL:
            return {
                error: payload
            };

        case ADD_USER_TRANSACTION:
            return {
                transactions: [
                    ...state.transactions,
                    payload
                ]
            };

        case USER_UNLOADED:
            return {
                transactions: []
            };

        default:
            return state;
    }
};

export const allBotTransactionsReducer = (state = { transactions: [] }, { type, payload }) => {
    switch (type) {
        case ALL_BOT_TRANSACTIONS_SUCCESS:
            return {
                transactions: payload
            };

        case ALL_BOT_TRANSACTIONS_FAIL:
            return {
                error: payload
            };

        case ADD_BOT_TRANSACTION:
            return {
                transactions: [
                    ...state.transactions,
                    payload
                ]
            };

        case USER_UNLOADED:
            return {
                transactions: []
            };

        default:
            return state;
    }
};

export const adminWalletReducer = (state = { adminWallet: {}, currencies: {} }, { type, payload }) => {
    switch (type) {
        case ADMIN_WALLET_SUCCESS:
            return {
                ...state,
                adminWallet: payload,
            };
        
        case ADMIN_WALLET_FAIL:
            return {
                ...state,
                error: payload
            };

        case ADMIN_WALLET_CURRENCIES_SUCCESS:
            state.currencies[payload.chainId] = payload.currencies;
            return {
                ...state,
            };
        
        default:
            return state;
    }
};

export const botBalancesReducer = (state = { balances: [] }, { type, payload }) => {
    switch (type) {
        case ALL_BOT_BALANCES_SUCCESS:
            return {
                balances: payload
            };
        
        case ALL_BOT_BALANCES_FAIL:
            return {
                error: payload
            };

        case USER_UNLOADED:
            return {
                balances: []
            };
        
        default:
            return state;
    }
};

export const userRewardsReducer = (state = { rewards: [] }, { type, payload }) => {
    switch (type) {
        case ALL_USER_REWARDS_SUCCESS:
            return {
                rewards: payload
            };
        
        case ALL_USER_REWARDS_FAIL:
            return {
                error: payload
            };

        case USER_UNLOADED:
            return {
                rewards: []
            };
        
        default:
            return state;
    }
};
