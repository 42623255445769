import axios from 'axios';
import { notification } from 'antd';
import {
    ALL_BOTS_SUCCESS,
    ALL_BOTS_FAIL,
    NEW_BOT_SUCCESS,
    //NEW_BOT_FAIL,
    UPDATE_BOT_SUCCESS,
    //UPDATE_BOT_FAIL,
    DELETE_BOT_SUCCESS,
    //DELETE_BOT_FAIL,
    START_BOT_SUCCESS,
    //START_BOT_FAIL,
    STOP_BOT_SUCCESS,
    //STOP_BOT_FAIL,
} from '../constants/botConstants';
import { addNotification } from './notificationAction';
import { getServerURL } from '../constants/server';

export const getAllBots = () => async (dispatch) => {
    try {
        const { data } = await axios.get(`${getServerURL()}/api/v1/bots/all`, {
            headers: {
                'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        });
        dispatch({
            type: ALL_BOTS_SUCCESS,
            payload: data.bots,
        });
    }
    catch (error) {
        console.log(error);
        dispatch({
            type: ALL_BOTS_FAIL,
            payload: "Failed to get all bots",
        });
    }
};

export const addNewBot = ({name, description, type, path}) => async(dispatch) => {
    try {
        const { data } = await axios.post(`${getServerURL()}/api/v1/bots/new`, 
            { name, description, type, path },
            { headers: { 'Authorization': `bearer ${localStorage.getItem('token')}` } });
        notification.success({
            message: 'Bot',
            description: 'New bot has been added successfully',
        });
        dispatch({
            type: NEW_BOT_SUCCESS,
            payload: data.bots,
        });
        dispatch(addNotification("Bot", "bot", `A bot "${name}" has been added`, "all"));
    }
    catch (error) {
        notification.warning({
            message: 'Bot',
            description: 'Failed to add new bot',
        });
        console.log(error);
    }
}

export const updateBot = (botId, {name, description, type, path}) => async(dispatch) => {
    try {
        const { data } = await axios.put(`${getServerURL()}/api/v1/bots/${botId}/update`, 
            { name, description, type, path },
            { headers: { 'Authorization': `bearer ${localStorage.getItem('token')}` } });
        notification.success({
            message: 'Bot',
            description: 'A bot bas been updated successfully',
        });
        dispatch({
            type: UPDATE_BOT_SUCCESS,
            payload: data.bots,
        });
        dispatch(addNotification("Bot", "bot", `A bot "${name}" has been updated`, "all"));
    }
    catch (error) {
        notification.warning({
            message: 'Bot',
            description: 'Failed to udpate a bot',
        });
        console.log(error);
    }
}

export const deleteBot = (botId) => async(dispatch) => {
    try {
        const { data } = await axios.get(`${getServerURL()}/api/v1/bots/${botId}/delete`, 
            { headers: { 'Authorization': `bearer ${localStorage.getItem('token')}` } });
        notification.success({
            message: 'Bot',
            description: 'A bot has been deleted successfully',
        });
        dispatch({
            type: DELETE_BOT_SUCCESS,
            payload: data.bots,
        });

        dispatch(addNotification("Bot", "bot", `A bot "${data.name}" has been deleted`, "all"));
    }
    catch (error) {
        notification.warning({
            message: 'Bot',
            description: 'Failed to delete a bot',
        });
        console.log(error);
    }
}

export const startBot = (botId) => async(dispatch) => {
    try {
        const { data } = await axios.get(`${getServerURL()}/api/v1/bots/${botId}/start`, 
            { headers: { 'Authorization': `bearer ${localStorage.getItem('token')}` } });
        notification.success({
            message: 'Bot',
            description: 'A bot has been started successfully',
        });
        dispatch({
            type: START_BOT_SUCCESS,
            payload: data.botId,
        });
    }
    catch (error) {
        notification.warning({
            message: 'Bot',
            description: 'Failed to start a bot',
        });
        console.log(error);
    }
}

export const stopBot = (botId) => async(dispatch) => {
    try {
        const { data } = await axios.get(`${getServerURL()}/api/v1/bots/${botId}/stop`, 
            { headers: { 'Authorization': `bearer ${localStorage.getItem('token')}` } });
        notification.success({
            message: 'Bot',
            description: 'A bot has been stopped successfully',
        });
        dispatch({
            type: STOP_BOT_SUCCESS,
            payload: data.botId,
        });
    }
    catch (error) {
        notification.warning({
            message: 'Bot',
            description: 'Failed to stop a bot',
        });
        console.log(error);
    }
}
