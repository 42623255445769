export const ADMIN_WALLET_SUCCESS = "ADMIN_WALLET_SUCCESS";
export const ADMIN_WALLET_FAIL = "ADMIN_WALLET_FAIL";
export const ADMIN_WALLET_CURRENCIES_SUCCESS = "ADMIN_WALLET_CURRENCIES_SUCCESS";
export const ALL_USER_TRANSACTIONS_SUCCESS = "ALL_USER_TRANSACTIONS_SUCCESS";
export const ALL_USER_TRANSACTIONS_FAIL = "ALL_USER_TRANSACTIONS_FAIL";
export const ADD_USER_TRANSACTION = "ADD_USER_TRANSACTION";
export const ALL_BOT_TRANSACTIONS_SUCCESS = "ALL_BOT_TRANSACTIONS_SUCCESS";
export const ALL_BOT_TRANSACTIONS_FAIL = "ALL_BOT_TRANSACTIONS_FAIL";
export const ADD_BOT_TRANSACTION = "ADD_BOT_TRANSACTION";
export const ALL_BOT_BALANCES_SUCCESS = "ALL_BOT_BALANCES_SUCCESS";
export const ALL_BOT_BALANCES_FAIL = "ALL_BOT_BALANCES_FAIL";
export const ALL_USER_REWARDS_SUCCESS = "ALL_USER_REWARDS_SUCCESS";
export const ALL_USER_REWARDS_FAIL = "ALL_USER_REWARDS_FAIL";
