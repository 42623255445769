import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import {
    Card,
    Col,
    Row,
    Typography,
    Tooltip,
    Progress,
    Upload,
    message,
    Button,
    Timeline,
    Radio,
} from "antd";
import {
    ToTopOutlined,
    MenuUnfoldOutlined,
    RightOutlined,
} from "@ant-design/icons";
import Paragraph from "antd/lib/typography/Paragraph";
import { getAllUsers, getAllVisits } from "../actions/userAction";

import ava1 from "../assets/images/logo-shopify.svg";
import ava2 from "../assets/images/logo-atlassian.svg";
import ava3 from "../assets/images/logo-slack.svg";
import team1 from "../assets/images/team-1.jpg";
import team2 from "../assets/images/team-2.jpg";
import team3 from "../assets/images/team-3.jpg";
import team4 from "../assets/images/team-4.jpg";
import card from "../assets/images/info-card-1.jpg";

function Home() {
    const { Title, Text } = Typography;
    const onChange = (e) => console.log(`radio checked:${e.target.value}`);
    const [reverse, setReverse] = useState(false);

    const dollor = [
        <svg
            width="22"
            height="22"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            key={0}>
            <path
                d="M8.43338 7.41784C8.58818 7.31464 8.77939 7.2224 9 7.15101L9.00001 8.84899C8.77939 8.7776 8.58818 8.68536 8.43338 8.58216C8.06927 8.33942 8 8.1139 8 8C8 7.8861 8.06927 7.66058 8.43338 7.41784Z"
                fill="#fff" />
            <path
                d="M11 12.849L11 11.151C11.2206 11.2224 11.4118 11.3146 11.5666 11.4178C11.9308 11.6606 12 11.8861 12 12C12 12.1139 11.9308 12.3394 11.5666 12.5822C11.4118 12.6854 11.2206 12.7776 11 12.849Z"
                fill="#fff" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 5C11 4.44772 10.5523 4 10 4C9.44772 4 9 4.44772 9 5V5.09199C8.3784 5.20873 7.80348 5.43407 7.32398 5.75374C6.6023 6.23485 6 7.00933 6 8C6 8.99067 6.6023 9.76515 7.32398 10.2463C7.80348 10.5659 8.37841 10.7913 9.00001 10.908L9.00002 12.8492C8.60902 12.7223 8.31917 12.5319 8.15667 12.3446C7.79471 11.9275 7.16313 11.8827 6.74599 12.2447C6.32885 12.6067 6.28411 13.2382 6.64607 13.6554C7.20855 14.3036 8.05956 14.7308 9 14.9076L9 15C8.99999 15.5523 9.44769 16 9.99998 16C10.5523 16 11 15.5523 11 15L11 14.908C11.6216 14.7913 12.1965 14.5659 12.676 14.2463C13.3977 13.7651 14 12.9907 14 12C14 11.0093 13.3977 10.2348 12.676 9.75373C12.1965 9.43407 11.6216 9.20873 11 9.09199L11 7.15075C11.391 7.27771 11.6808 7.4681 11.8434 7.65538C12.2053 8.07252 12.8369 8.11726 13.254 7.7553C13.6712 7.39335 13.7159 6.76176 13.354 6.34462C12.7915 5.69637 11.9405 5.26915 11 5.09236V5Z"
                fill="#fff" />
        </svg>,
    ];
    const profile = [
        <svg
            width="22"
            height="22"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            key={0}>
            <path
                d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z"
                fill="#fff" />
            <path
                d="M17 6C17 7.65685 15.6569 9 14 9C12.3431 9 11 7.65685 11 6C11 4.34315 12.3431 3 14 3C15.6569 3 17 4.34315 17 6Z"
                fill="#fff" />
            <path
                d="M12.9291 17C12.9758 16.6734 13 16.3395 13 16C13 14.3648 12.4393 12.8606 11.4998 11.6691C12.2352 11.2435 13.0892 11 14 11C16.7614 11 19 13.2386 19 16V17H12.9291Z"
                fill="#fff" />
            <path
                d="M6 11C8.76142 11 11 13.2386 11 16V17H1V16C1 13.2386 3.23858 11 6 11Z"
                fill="#fff" />
        </svg>,
    ];

    const heart = [
        <svg
            width="22"
            height="22"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            key={0}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.17157 5.17157C4.73367 3.60948 7.26633 3.60948 8.82843 5.17157L10 6.34315L11.1716 5.17157C12.7337 3.60948 15.2663 3.60948 16.8284 5.17157C18.3905 6.73367 18.3905 9.26633 16.8284 10.8284L10 17.6569L3.17157 10.8284C1.60948 9.26633 1.60948 6.73367 3.17157 5.17157Z"
                fill="#fff" />
        </svg>,
    ];

    const cart = [
        <svg
            width="22"
            height="22"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            key={0}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 2C7.79086 2 6 3.79086 6 6V7H5C4.49046 7 4.06239 7.38314 4.00612 7.88957L3.00612 16.8896C2.97471 17.1723 3.06518 17.455 3.25488 17.6669C3.44458 17.8789 3.71556 18 4 18H16C16.2844 18 16.5554 17.8789 16.7451 17.6669C16.9348 17.455 17.0253 17.1723 16.9939 16.8896L15.9939 7.88957C15.9376 7.38314 15.5096 7 15 7H14V6C14 3.79086 12.2091 2 10 2ZM12 7V6C12 4.89543 11.1046 4 10 4C8.89543 4 8 4.89543 8 6V7H12ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10ZM13 9C12.4477 9 12 9.44772 12 10C12 10.5523 12.4477 11 13 11C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9Z"
                fill="#fff" />
        </svg>,
    ];

    const list = [
        {
            img: ava1,
            Title: "Soft UI Shopify Version",
            bud: "$14,000",
            progress: <Progress percent={60} size="small" />,
            member: (
                <div className="avatar-group mt-2">
                    <Tooltip placement="bottom" title="Ryan Tompson">
                        <img className="tootip-img" src={team1} alt="" />
                    </Tooltip>
                    <Tooltip placement="bottom" title="Romina Hadid">
                        <img className="tootip-img" src={team2} alt="" />
                    </Tooltip>
                    <Tooltip placement="bottom" title="Alexander Smith">
                        <img className="tootip-img" src={team3} alt="" />
                    </Tooltip>
                    <Tooltip placement="bottom" title="Jessica Doe">
                        <img className="tootip-img" src={team4} alt="" />
                    </Tooltip>
                </div>
            ),
        },
        {
            img: ava2,
            Title: "Progress Track",
            bud: "$3,000",
            progress: <Progress percent={10} size="small" />,
            member: (
                <div className="avatar-group mt-2">
                    <Tooltip placement="bottom" title="Ryan Tompson">
                        <img className="tootip-img" src={team1} alt="" />
                    </Tooltip>
                    <Tooltip placement="bottom" title="Romina Hadid">
                        <img className="tootip-img" src={team2} alt="" />
                    </Tooltip>
                </div>
            ),
        },
        {
            img: ava3,
            Title: "Fix Platform Errors",
            bud: "Not Set",
            progress: <Progress percent={100} size="small" status="active" />,
            member: (
                <div className="avatar-group mt-2">
                    <Tooltip placement="bottom" title="Ryan Tompson">
                        <img className="tootip-img" src={team1} alt="" />
                    </Tooltip>
                    <Tooltip placement="bottom" title="Romina Hadid">
                        <img className="tootip-img" src={team1} alt="" />
                    </Tooltip>
                    <Tooltip placement="bottom" title="Alexander Smith">
                        <img className="tootip-img" src={team3} alt="" />
                    </Tooltip>
                </div>
            ),
        },
    ];

    const timelineList = [
        {
            title: "$2,400 - Redesign store",
            time: "09 JUN 7:20 PM",
            color: "green",
        },
        {
            title: "New order #3654323",
            time: "08 JUN 12:20 PM",
            color: "green",
        },
        {
            title: "Company server payments",
            time: "04 JUN 3:10 PM",
        },
        {
            title: "New card added for order #4826321",
            time: "02 JUN 2:45 PM",
        },
        {
            title: "Unlock folders for development",
            time: "18 MAY 1:30 PM",
        },
        {
            title: "New order #46282344",
            time: "14 MAY 3:30 PM",
            color: "gray",
        },
    ];

    const uploadProps = {
        name: "file",
        action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
        headers: {
            authorization: "authorization-text",
        },
        onChange(info) {
            if (info.file.status !== "uploading") {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === "done") {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === "error") {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const dispatch = useDispatch();
    const { trades } = useSelector(state => state.botTrades);
    const { transactions } = useSelector(state => state.userTransactions);
    const { users } = useSelector(state => state.users);
    const { visits } = useSelector(state => state.visits);

    useEffect(() => {
        dispatch(getAllUsers());
        dispatch(getAllVisits());
    }, [dispatch]);

    const today = new Date();
    const yesterday = new Date(today.getTime() - 86400000);
    let todayProfits = 0;
    let yesterdayProfits = 0;
    for (let i = 0; i < trades?.length; i++) {
        const d = new Date(trades[i].executedAt);
        if (yesterday.getFullYear() === d.getFullYear() &&
            yesterday.getMonth() === d.getMonth() &&
            yesterday.getDate() === d.getDate()) {
            // TODO: Currency Conversion
            yesterdayProfits += trades[i].profit;
        }

        if (today.getFullYear() === d.getFullYear() &&
            today.getMonth() === d.getMonth() &&
            today.getDate() === d.getDate()) {
            // TODO: Currency Conversion
            todayProfits += trades[i].profit;
        }
    }

    let todayDeposits = 0;
    let yesterdayDeposits = 0;
    for (let i = 0; i < transactions?.length; i++) {
        const d = new Date(transactions[i].time);
        if (yesterday.getFullYear() === d.getFullYear() &&
            yesterday.getMonth() === d.getMonth() &&
            yesterday.getDate() === d.getDate()) {
            // TODO: Currency Conversion
            yesterdayDeposits += transactions[i].amount;
        }

        if (today.getFullYear() === d.getFullYear() &&
            today.getMonth() === d.getMonth() &&
            today.getDate() === d.getDate()) {
            // TODO: Currency Conversion
            todayDeposits += transactions[i].amount;
        }
    }

    let todayCreatedUsers = 0;
    let yesterdayCreatedUsers = 0;
    const userList = users?.filter(item => {
        return item.role === "user";
    });
    for (let i = 0; i < userList?.length; i++) {
        const d = new Date(userList[i].createdAt);
        if (yesterday.getFullYear() === d.getFullYear() &&
            yesterday.getMonth() === d.getMonth() &&
            yesterday.getDate() === d.getDate()) {
            yesterdayCreatedUsers++;
        }

        if (today.getFullYear() === d.getFullYear() &&
            today.getMonth() === d.getMonth() &&
            today.getDate() === d.getDate()) {
            todayCreatedUsers++;
        }
    }

    let todayVisit = visits?.find(item => {
        const d = new Date(item.date);
        return (today.getFullYear() === d.getFullYear() &&
            today.getMonth() === d.getMonth() &&
            today.getDate() === d.getDate());
    });
    let yesterdayVisit = visits?.find(item => {
        const d = new Date(item.date);
        return (yesterday.getFullYear() === d.getFullYear() &&
            yesterday.getMonth() === d.getMonth() &&
            yesterday.getDate() === d.getDate());
    });

    const count = [
        {
            today: "Today’s Profits",
            title: Number(todayProfits).toFixed(2),
            persent: yesterdayProfits > 0 ? (Number((todayProfits - yesterdayProfits) / yesterdayProfits).toFixed(2) * Number(100)) + "%" : "0%",
            icon: dollor,
            bnb: "bnb2",
        },
        {
            today: "Today’s Deposits",
            title: Number(todayDeposits).toFixed(2),
            persent: yesterdayDeposits > 0 ? (Number((todayDeposits - yesterdayDeposits) / yesterdayDeposits).toFixed(2) * Number(100)) + "%" : "0%",
            icon: cart,
            bnb: "bnb2",
        },
        {
            today: "Today’s Users",
            title: todayVisit ? todayVisit.count : 0,
            persent: (todayVisit && yesterdayVisit && yesterdayVisit.count > 0) ? (Number((todayVisit.count - yesterdayVisit.count) / yesterdayVisit.count).toFixed(2) * Number(100)) + "%" : "0%",
            icon: profile,
            bnb: "bnb2",
        },
        {
            today: "New Users",
            title: todayCreatedUsers,
            persent: yesterdayCreatedUsers > 0 ? (Number((todayCreatedUsers - yesterdayCreatedUsers) / yesterdayCreatedUsers).toFixed(2) * Number(100)) + "%" : "0%",
            icon: heart,
            bnb: "bnb2",
        },
    ];

    const [ transChartMode, setTransChartMode ] = useState("deposit_1M");
    const onChagngeTransMode = (e) => {
        setTransChartMode(e.target.value);
    }

    const [ profitChartMode, setProfitChartMode ] = useState("profit_1M");
    const onChangeProfitMode = (e) => {
        setProfitChartMode(e.target.value);
    }

    const generateProfitChart = () => {
        const eChart = {
            series: [
                {
                    name: "Profits",
                    data: [],
                    color: "#fff",
                },
            ],
            
            options: {
                chart: {
                    type: "bar",
                    width: "100%",
                    height: "auto",
                    toolbar: {
                        show: false,
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: "55%",
                        borderRadius: 5,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    show: true,
                    width: 1,
                    colors: ["transparent"],
                },
                grid: {
                    show: true,
                    borderColor: "#ccc",
                    strokeDashArray: 2,
                },
                xaxis: {
                    categories: [],
                    labels: {
                        show: true,
                        align: "right",
                        minWidth: 0,
                        maxWidth: 160,
                        style: {
                            colors: [
                                "#fff",
                                "#fff",
                                "#fff",
                                "#fff",
                                "#fff",
                                "#fff",
                                "#fff",
                                "#fff",
                                "#fff",
                                "#fff",
                            ],
                        },
                    },
                },
                yaxis: {
                    labels: {
                        show: true,
                        align: "right",
                        minWidth: 0,
                        maxWidth: 160,
                        style: {
                            colors: [
                                "#fff",
                                "#fff",
                                "#fff",
                                "#fff",
                                "#fff",
                                "#fff",
                                "#fff",
                                "#fff",
                                "#fff",
                                "#fff",
                            ],
                        },
                    },
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return "$ " + val + " thousands";
                        },
                    },
                },
            },
        };

        if (profitChartMode === "profit_1M") {
            const now2 = new Date(today.getFullYear(), today.getMonth(), today.getDate());
            const nowTime = now2.getTime();
            for (let d = 29; d >= 0; d--) {
                const from = new Date(nowTime - 86400000 * d);
                const to = new Date(from.getTime() + 86400000);
                // TODO: Currency Conversion
                let profit = 0;
                for (let i = 0; i < trades?.length; i++) {
                    const d = new Date(trades[i].executedAt);
                    if (from <= d && d < to)
                        profit += trades[i].profit;
                }

                eChart.series[0].data = [
                    ...eChart.series[0].data,
                    Number(profit).toFixed(2)
                ];
                eChart.options.xaxis.categories = [
                    ...eChart.options.xaxis.categories,
                    `${from.getMonth() + 1}/${from.getDate()}`
                ];
            }
        }
        else if (profitChartMode === "profit_1Y") {
            for (let m = 11; m >= 0; m--) {
                const from = (today.getMonth() < m) ? new Date(today.getFullYear() - 1, today.getMonth() + 12 - m): new Date(today.getFullYear(), today.getMonth() - m);
                const to = (from.getMonth() === 11) ? new Date(from.getFullYear() + 1, 0): new Date(from.getFullYear(), from.getMonth() + 1);
                // TODO: Currency Conversion
                let profit = 0;
                for (let i = 0; i < trades?.length; i++) {
                    const d = new Date(trades[i].executedAt);
                    if (from <= d && d < to)
                        profit += trades[i].profit;
                }
    
                eChart.series[0].data = [
                    ...eChart.series[0].data,
                    Number(profit).toFixed(2)
                ];
                eChart.options.xaxis.categories = [
                    ...eChart.options.xaxis.categories,
                    `${from.getFullYear()}/${from.getMonth() + 1}`
                ];
            }
        }

        let maximumProfit = Number(eChart.series[0].data[0]);
        let minimumProfit = maximumProfit;
        let averageProfit = maximumProfit;
        for (let i = 1; i < eChart.series[0].data.length; i++) {
            if (maximumProfit < Number(eChart.series[0].data[i]))
                maximumProfit = Number(eChart.series[0].data[i]);
            if (minimumProfit > Number(eChart.series[0].data[i]))
                minimumProfit = Number(eChart.series[0].data[i]);
            averageProfit += Number(eChart.series[0].data[i]);
        }
        averageProfit /= eChart.series[0].data.length;

        return { eChart, minimumProfit, maximumProfit, averageProfit };
    };

    const generateTransChart = () => {
        const lineChart = {
            series: [
                {
                    name: "Deposit",
                    data: [],
                    offsetY: 0,
                },
                {
                    name: "Withdraw",
                    data: [],
                    offsetY: 0,
                },
            ],
        
            options: {
                chart: {
                    width: "100%",
                    height: 300,
                    type: "area",
                    toolbar: {
                        show: false,
                    },
                },
        
                legend: {
                    show: true,
                },
        
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: "straight",
                },
        
                yaxis: {
                    labels: {
                        style: {
                            fontSize: "14px",
                            fontWeight: 600,
                            colors: ["#8c8c8c"],
                        },
                    },
                },
        
                xaxis: {
                    labels: {
                        style: {
                            fontSize: "14px",
                            fontWeight: 600,
                            colors: [
                                "#8c8c8c",
                                "#8c8c8c",
                                "#8c8c8c",
                                "#8c8c8c",
                                "#8c8c8c",
                                "#8c8c8c",
                                "#8c8c8c",
                                "#8c8c8c",
                                "#8c8c8c",
                            ],
                        },
                    },
                    categories: [],
                },
        
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val;
                        },
                    },
                },
            },
        };

        if (transChartMode === "deposit_1M") {
            const now2 = new Date(today.getFullYear(), today.getMonth(), today.getDate());
            const nowTime = now2.getTime();
            for (let d = 29; d >= 0; d--) {
                const from = new Date(nowTime - 86400000 * d);
                const to = new Date(from.getTime() + 86400000);
                // TODO: Currency Conversion
                let depositAmount = 0;
                let withdrawAmount = 0;
                for (let i = 0; i < transactions?.length; i++) {
                    const d = new Date(transactions[i].time);
                    if (from <= d && d < to) {
                        if (transactions[i].type === "deposit")
                            depositAmount += transactions[i].amount;
                        else
                            withdrawAmount += transactions[i].amount;
                    }
                }

                lineChart.series[0].data = [
                    ...lineChart.series[0].data,
                    Number(depositAmount).toFixed(2),
                ];
                lineChart.series[1].data = [
                    ...lineChart.series[1].data,
                    Number(withdrawAmount).toFixed(2),
                ];
                lineChart.options.xaxis.categories = [
                    ...lineChart.options.xaxis.categories,
                    `${from.getMonth() + 1}/${from.getDate()}`
                ];
            }
        }
        else if (transChartMode === "deposit_1Y") {
            for (let m = 11; m >= 0; m--) {
                const from = (today.getMonth() < m) ? new Date(today.getFullYear() - 1, today.getMonth() + 12 - m): new Date(today.getFullYear(), today.getMonth() - m);
                const to = (from.getMonth() === 11) ? new Date(from.getFullYear() + 1, 0): new Date(from.getFullYear(), from.getMonth() + 1);
                // TODO: Currency Conversion
                let depositAmount = 0;
                let withdrawAmount = 0;
                for (let i = 0; i < transactions?.length; i++) {
                    const d = new Date(transactions[i].time);
                    if (from <= d && d < to) {
                        if (transactions[i].type === "deposit")
                            depositAmount += transactions[i].amount;
                        else
                            withdrawAmount += transactions[i].amount;
                    }
                }
    
                lineChart.series[0].data = [
                    ...lineChart.series[0].data,
                    Number(depositAmount).toFixed(2),
                ];
                lineChart.series[1].data = [
                    ...lineChart.series[1].data,
                    Number(withdrawAmount).toFixed(2),
                ];
                lineChart.options.xaxis.categories = [
                    ...lineChart.options.xaxis.categories,
                    `${from.getFullYear()}/${from.getMonth() + 1}`
                ];
            }
        }

        let averageDeposit = 0;
        let averageWithdraw = 0;
        for (let i = 0; i < lineChart.series[0].data.length; i++) {
            averageDeposit += Number(lineChart.series[0].data[i]);
            averageWithdraw += Number(lineChart.series[1].data[i]);
        }
        averageDeposit /= lineChart.series[0].data.length;
        averageWithdraw /= lineChart.series[1].data.length;

        return { lineChart, averageDeposit, averageWithdraw };
    };

    const { eChart, minimumProfit, maximumProfit, averageProfit } = generateProfitChart();
    const { lineChart, averageDeposit, averageWithdraw } = generateTransChart();

    return (
        <>
            <div className="layout-content">
                <Row className="rowgap-vbox" gutter={[24, 0]}>
                    {
                        count.map((c, index) => (
                            <Col
                                key={index}
                                xs={24}
                                sm={24}
                                md={12}
                                lg={6}
                                xl={6}
                                className="mb-24">
                                <Card bordered={false} className="criclebox ">
                                    <div className="number">
                                        <Row align="middle" gutter={[24, 0]}>
                                            <Col xs={18}>
                                                <span>{c.today}</span>
                                                <Title level={3}>
                                                    {c.title} <small className={c.bnb}>{c.persent}</small>
                                                </Title>
                                            </Col>
                                            <Col xs={6}>
                                                <div className="icon-box">{c.icon}</div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        ))
                    }
                </Row>
                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={10} className="mb-24">
                        <Card
                            bordered={false}
                            className="criclebox tablespace h-full"
                            title="Overview of the profits"
                            extra={
                                <>
                                    <Radio.Group onChange={onChangeProfitMode} defaultValue="profit_1M">
                                        <Radio.Button value="profit_1M">1M</Radio.Button>
                                        <Radio.Button value="profit_1Y">1Y</Radio.Button>
                                    </Radio.Group>
                                </>
                            }>
                            <div className="linechart">
                                <div>
                                    <Paragraph className="lastweek ml-24px mt-12px">
                                        Average: {Number(averageProfit).toFixed(2)}
                                    </Paragraph>
                                </div>
                            </div>
                            <ReactApexChart
                                className="bar-chart lg:ml-24px lg:mr-24px"
                                options={eChart.options}
                                series={eChart.series}
                                type="bar"
                                height={220} />
                            <div className="chart-vistior ml-24px">
                                <Paragraph className="lastweek">
                                    Highest: {Number(maximumProfit).toFixed(2)}
                                </Paragraph>
                                <Paragraph className="lastweek">
                                    Lowest: {Number(minimumProfit).toFixed(2)}
                                </Paragraph>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={14} className="mb-24">
                        <Card
                            bordered={false}
                            className="criclebox tablespace h-full"
                            title="Overview of deposit and withdraw"
                            extra={
                                <>
                                    <Radio.Group onChange={onChagngeTransMode} defaultValue="deposit_1M">
                                        <Radio.Button value="deposit_1M">1M</Radio.Button>
                                        <Radio.Button value="deposit_1Y">1Y</Radio.Button>
                                    </Radio.Group>
                                </>
                            }>
                            <div className="linechart ml-16px mt-12px">
                                <div>
                                    <Paragraph className="lastweek">
                                        Average: {Number(averageDeposit).toFixed(2)} / {Number(averageWithdraw).toFixed(2)}
                                    </Paragraph>
                                </div>
                            </div>
                            <ReactApexChart
                                className="full-width"
                                options={lineChart.options}
                                series={lineChart.series}
                                type="area"
                                height={350}
                                width={"100%"} />
                        </Card>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={16} className="mb-24">
                        <Card bordered={false} className="criclebox cardbody h-full">
                            <div className="project-ant">
                                <div>
                                    <Title level={5}>Projects</Title>
                                    <Paragraph className="lastweek">
                                        done this month<span className="blue">40%</span>
                                    </Paragraph>
                                </div>
                                <div className="ant-filtertabs">
                                    <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                                        <Radio.Group onChange={onChange} defaultValue="a">
                                            <Radio.Button value="a">ALL</Radio.Button>
                                            <Radio.Button value="b">ONLINE</Radio.Button>
                                            <Radio.Button value="c">STORES</Radio.Button>
                                        </Radio.Group>
                                    </div>
                                </div>
                            </div>
                            <div className="ant-list-box table-responsive">
                                <table className="width-100">
                                    <thead>
                                        <tr>
                                            <th>COMPANIES</th>
                                            <th>MEMBERS</th>
                                            <th>BUDGET</th>
                                            <th>COMPLETION</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            list.map((d, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <h6>
                                                            <img src={d.img} alt="" className="avatar-sm mr-10" />{" "}
                                                            {d.Title}
                                                        </h6>
                                                    </td>
                                                    <td>{d.member}</td>
                                                    <td>
                                                        <span className="text-xs font-weight-bold">
                                                            {d.bud}{" "}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <div className="percent-progress">{d.progress}</div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="uploadfile shadow-none">
                                <Upload {...uploadProps}>
                                    <Button
                                        type="dashed"
                                        className="ant-full-box"
                                        icon={<ToTopOutlined />}>
                                        <span className="click">Click to Upload</span>
                                    </Button>
                                </Upload>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">
                        <Card bordered={false} className="criclebox h-full">
                            <div className="timeline-box">
                                <Title level={5}>Orders History</Title>
                                <Paragraph className="lastweek" style={{ marginBottom: 24 }}>
                                    this month <span className="bnb2">20%</span>
                                </Paragraph>

                                <Timeline
                                    pending="Recording..."
                                    className="timelinelist"
                                    reverse={reverse}>
                                    {
                                        timelineList.map((t, index) => (
                                            <Timeline.Item color={t.color} key={index}>
                                                <Title level={5}>{t.title}</Title>
                                                <Text>{t.time}</Text>
                                            </Timeline.Item>
                                        ))
                                    }
                                </Timeline>
                                <Button
                                    type="primary"
                                    className="width-100"
                                    onClick={() => setReverse(!reverse)}>
                                    {<MenuUnfoldOutlined />} REVERSE
                                </Button>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col xs={24} md={12} sm={24} lg={12} xl={14} className="mb-24">
                        <Card bordered={false} className="criclebox h-full">
                            <Row gutter>
                                <Col
                                    xs={24}
                                    md={12}
                                    sm={24}
                                    lg={12}
                                    xl={14}
                                    className="mobile-24">
                                    <div className="h-full col-content p-20">
                                        <div className="ant-muse">
                                            <Text>Built by developers</Text>
                                            <Title level={5}>HighPerformBot for Ant Design</Title>
                                            <Paragraph className="lastweek mb-36">
                                                From colors, cards, typography to complex elements, you
                                                will find the full documentation.
                                            </Paragraph>
                                        </div>
                                        <div className="card-footer">
                                            <a className="icon-move-right" href="#pablo">
                                                Read More
                                                {<RightOutlined />}
                                            </a>
                                        </div>
                                    </div>
                                </Col>
                                <Col
                                    xs={24}
                                    md={12}
                                    sm={24}
                                    lg={12}
                                    xl={10}
                                    className="col-img">
                                    <div className="ant-cret text-right">
                                        <img src={card} alt="" className="border10" />
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={24} md={12} sm={24} lg={12} xl={10} className="mb-24">
                        <Card bordered={false} className="criclebox card-info-2 h-full">
                            <div className="gradent h-full col-content">
                                <div className="card-content">
                                    <Title level={5}>Work with the best</Title>
                                    <p>
                                        Wealth creation is an evolutionarily recent positive-sum
                                        game. It is all about who take the opportunity first.
                                    </p>
                                </div>
                                <div className="card-footer">
                                    <a className="icon-move-right" href="#pablo">
                                        Read More
                                        <RightOutlined />
                                    </a>
                                </div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
  );
}

export default Home;
