import axios from 'axios';
import { notification } from 'antd';
import {
    POLICY_PORTIONS_SUCCESS,
    POLICY_PORTIONS_FAIL,
    POLICY_UPDATE_PORTION_SUCCESS,
    POLICY_UPDATE_PORTION_FAIL,
    POLICY_DELETE_PORTION_SUCCESS,
    POLICY_DELETE_PORTION_FAIL,
    POLICY_FEES_SUCCESS,
    POLICY_FEES_FAIL,
    POLICY_UPDATE_FEE_SUCCESS,
    POLICY_UPDATE_FEE_FAIL,
    POLICY_DELETE_FEE_SUCCESS,
    POLICY_DELETE_FEE_FAIL,
} from "../constants/policyConstants";
import { addNotification } from './notificationAction';
import { getServerURL } from '../constants/server';

export const getAllPortions = () => async (dispatch) => {
    try {
        const { data } = await axios.get(`${getServerURL()}/api/v1/policy/portion/all`, {
            headers: {
                'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        });
        dispatch({
            type: POLICY_PORTIONS_SUCCESS,
            payload: data.portions,
        });
    }
    catch (error) {
        console.log(error);
        dispatch({
            type: POLICY_PORTIONS_FAIL,
            payload: "Failed to get all portions",
        });
    }
};

export const updatePortion = (portionId, newData) => async(dispatch) => {
    try {
        const { data } = await axios.put(`${getServerURL()}/api/v1/policy/portion/${portionId}/update`,
            newData,
            { headers: { 'Authorization': `bearer ${localStorage.getItem('token')}` } });
        notification.success({
            message: 'Portion of Profit',
            description: 'A portion was updated successfully',
        });
        dispatch({
            type: POLICY_UPDATE_PORTION_SUCCESS,
            payload: data.portions,
        });

        dispatch(addNotification("Policy", "portion", `The portion of ${data.portion.category} was changed to ${data.portion.value}%`, "all"));
    }
    catch (error) {
        notification.warning({
            message: 'Portion of Profit',
            description: 'Failed to update a portion',
        });
        console.log(error);
        dispatch({
            type: POLICY_UPDATE_PORTION_FAIL,
            payload: "Failed to update a portion",
        });
    }
}

export const deletePortion = (portionId) => async(dispatch) => {
    try {
        const { data } = await axios.get(`${getServerURL()}/api/v1/policy/portion/${portionId}/delete`, 
            { headers: { 'Authorization': `bearer ${localStorage.getItem('token')}` } });
        notification.success({
            message: 'Portion of Profit',
            description: 'A portion was deleted successfully',
        });
        dispatch({
            type: POLICY_DELETE_PORTION_SUCCESS,
            payload: data.portions,
        });
        dispatch(addNotification("Policy", "portion", `The portion of ${data.portion.category} was deleted`, "all"));
    }
    catch (error) {
        notification.warning({
            message: 'Portion of Profit',
            description: 'Failed to delete a portion',
        });
        console.log(error);
        dispatch({
            type: POLICY_DELETE_PORTION_FAIL,
            payload: "Failed to delete a portion",
        });
    }
}

export const getAllFees = () => async (dispatch) => {
    try {
        const { data } = await axios.get(`${getServerURL()}/api/v1/policy/fee/all`, {
            headers: {
                'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        });
        dispatch({
            type: POLICY_FEES_SUCCESS,
            payload: data.fees,
        });
    }
    catch (error) {
        console.log(error);
        dispatch({
            type: POLICY_FEES_FAIL,
            payload: "Failed to get all fees",
        });
    }
};

export const updateFee = (feeId, newData) => async(dispatch) => {
    try {
        const { data } = await axios.put(`${getServerURL()}/api/v1/policy/fee/${feeId}/update`,
            newData,
            { headers: { 'Authorization': `bearer ${localStorage.getItem('token')}` } });
        notification.success({
            message: 'Service Fee',
            description: 'A fee was updated successfully',
        });
        dispatch({
            type: POLICY_UPDATE_FEE_SUCCESS,
            payload: data.fees,
        });
        dispatch(addNotification("Policy", "portion", `${data.fee.category === "withdraw" ? "Withdrawal" : "Unknown"} fee was changed to ${data.fee.value}${data.fee.unit === "dollars" ? "$" : "%"}`, "all"));
    }
    catch (error) {
        notification.warning({
            message: 'Service Fee',
            description: 'Failed to update a fee',
        });
        console.log(error);
        dispatch({
            type: POLICY_UPDATE_FEE_FAIL,
            payload: "Failed to update a fee",
        });
    }
}

export const deleteFee = (feeId) => async(dispatch) => {
    try {
        const { data } = await axios.get(`${getServerURL()}/api/v1/policy/fee/${feeId}/delete`, 
            { headers: { 'Authorization': `bearer ${localStorage.getItem('token')}` } });
        notification.success({
            message: 'Service Fee',
            description: 'A service fee was deleted successfully',
        });
        dispatch({
            type: POLICY_DELETE_FEE_SUCCESS,
            payload: data.fees,
        });
        dispatch(addNotification("Policy", "portion", `${data.category === "withdraw" ? "Withdrawal" : "Unknown"} fee was deleted`, "all"));
    }
    catch (error) {
        notification.warning({
            message: 'Service Fee',
            description: 'Failed to delete a service fee',
        });
        console.log(error);
        dispatch({
            type: POLICY_DELETE_FEE_FAIL,
            payload: "Failed to delete a service fee",
        });
    }
}
