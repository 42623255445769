import axios from 'axios';
import io from 'socket.io-client';
import { notification } from 'antd';
import { getServerURL } from '../constants/server';
import {
    USER_LOADING,
    USER_LOADED,
    USER_UNLOADED,
    USER_ERROR,
    CLEAR_ERRORS,
    PROFILE_LOADING,
    PROFILE_SUCCESS,
    PROFILE_FAIL,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAIL,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL,
    ALL_USERS_FAIL,
    ALL_USERS_SUCCESS,
    VISITS_SUCCESS,
    VISITS_FAIL,
    ALL_DEVELOPERS_SUCCESS,
    ALL_DEVELOPERS_FAIL,
    ALL_MESSAGES_SUCCESS,
    ADD_NEW_MESSAGE,
    ADD_NEW_SUB_MESSAGE,
    ALL_LICENSES_SUCCESS,
    ADD_NEW_LICENSE,
} from '../constants/userConstants';
import {
    ADD_BOT_LOG,
    ALL_BOT_TRADES_SUCCESS,
    ADD_BOT_TRADE,
} from "../constants/botConstants";
import {
    ALL_USER_TRANSACTIONS_SUCCESS,
    ADD_USER_TRANSACTION,
    ALL_USER_REWARDS_SUCCESS,
    ALL_BOT_TRANSACTIONS_SUCCESS,
    ADD_BOT_TRANSACTION,
    ALL_BOT_BALANCES_SUCCESS,
} from '../constants/financeConstants';

const initWebSocket = (user, dispatch) => {
    let ws;
    try {
        ws = new io(getServerURL());

        ws.on("connect", () => {
            console.log('WebSocket connection established');
            ws.emit("NEW_USER", user._id);
        });

        ws.on("disconnect", () => {
            console.log('WebSocket connection closed');
        });

        ws.on('GET_NEW_BOT_LOG', (value) => {
            const m = JSON.parse(value);
            dispatch({
                type: ADD_BOT_LOG,
                payload: m
            });
        });

        ws.on('GET_ALL_BOT_TRADES', (value) => {
            const m = JSON.parse(value);
            dispatch({
                type: ALL_BOT_TRADES_SUCCESS,
                payload: m.trades
            });
        });

        ws.on('GET_NEW_BOT_TRADE', (value) => {
            const m = JSON.parse(value);
            dispatch({
                type: ADD_BOT_TRADE,
                payload: m
            });
        });

        ws.on('GET_ALL_USER_TRANSACTIONS', (value) => {
            const m = JSON.parse(value);
            dispatch({
                type: ALL_USER_TRANSACTIONS_SUCCESS,
                payload: m.transactions
            });
        });

        ws.on('GET_NEW_USER_TRANSACTION', (value) => {
            const m = JSON.parse(value);
            dispatch({
                type: ADD_USER_TRANSACTION,
                payload: m
            });
        });

        ws.on('GET_ALL_USER_REWARDS', (value) => {
            const m = JSON.parse(value);
            dispatch({
                type: ALL_USER_REWARDS_SUCCESS,
                payload: m.rewards
            });
        });

        ws.on('GET_ALL_BOT_TRANSACTIONS', (value) => {
            const m = JSON.parse(value);
            dispatch({
                type: ALL_BOT_TRANSACTIONS_SUCCESS,
                payload: m.transactions
            });
        });

        ws.on('GET_NEW_BOT_TRANSACTION', (value) => {
            const m = JSON.parse(value);
            dispatch({
                type: ADD_BOT_TRANSACTION,
                payload: m
            });
        });

        ws.on('GET_ALL_BOT_BALANCES', (value) => {
            const m = JSON.parse(value);
            dispatch({
                type: ALL_BOT_BALANCES_SUCCESS,
                payload: m.balances
            });
        });

        ws.on('GET_ALL_ADMIN_MESSAGES', (value) => {
            const m = JSON.parse(value);
            dispatch({
                type: ALL_MESSAGES_SUCCESS,
                payload: m.messages
            });
        });

        ws.on('GET_NEW_ADMIN_MESSAGE', (value) => {
            const m = JSON.parse(value);
            dispatch({
                type: ADD_NEW_MESSAGE,
                payload: m
            });
        });

        ws.on('GET_NEW_ADMIN_SUB_MESSAGE', (value) => {
            const m = JSON.parse(value);
            dispatch({
                type: ADD_NEW_SUB_MESSAGE,
                payload: m
            });
        });

        ws.on('GET_ALL_LICENSES', (value) => {
            const m = JSON.parse(value);
            dispatch({
                type: ALL_LICENSES_SUCCESS,
                payload: m.licenses
            });
        });

        ws.on('GET_NEW_LICENSE', (value) => {
            const m = JSON.parse(value);
            dispatch({
                type: ADD_NEW_LICENSE,
                payload: m
            });
        });
    } catch (e) {
        console.log(e);
    }

    return ws;
}

export const loginUser = (email, password) => async (dispatch) => {
    try {
        dispatch({ type: USER_LOADING });

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        }
        const { data } = await axios.post(
            `${getServerURL()}/api/v1/admin/login`,
            { email, password },
            config
        );

        localStorage.setItem('token', data.token);
        notification.success({
            message: 'Sign In!',
            description: 'You were logged in successfully',
        });
        
        const ws = initWebSocket(data.user, dispatch);
        dispatch({
            type: USER_LOADED,
            payload: {
                user: data.user,
                websocket: ws,
            }
        });
    }
    catch (error) {
        notification.warning({
            message: 'Sign In!',
            description: 'Invalid email or password',
        });
        console.log(error);
        dispatch({
            type: USER_ERROR,
            payload: "Failed to login",
        });
    }
};

export const logoutUser = () => async (dispatch) => {
    try {
        await axios.get(`${getServerURL()}/api/v1/admin/logout`, {
            headers: {
                'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        });
        localStorage.removeItem('token');
        dispatch({ type: USER_UNLOADED });
    }
    catch (error) {
        console.log(error);
        dispatch({
            type: USER_ERROR,
            payload: "Failed to logout",
        });
    }
};

export const loadUser = () => async (dispatch) => {
    try {
        dispatch({ type: USER_LOADING });
        const { data } = await axios.get(`${getServerURL()}/api/v1/admin/me`, {
            headers: {
                'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        });

        const ws = initWebSocket(data.user, dispatch);
        dispatch({
            type: USER_LOADED,
            payload: {
                user: data.user,
                websocket: ws,
            }
        });
    }
    catch (error) {
        console.log(error);
        dispatch({
            type: USER_ERROR,
            payload: "Load user fail",
        });
    }
};

export const registerAdmin = (userData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': `bearer ${localStorage.getItem('token')}`,
            },
        }
        const { data } = await axios.post(
            `${getServerURL()}/api/v1/admin/addUser`,
            userData,
            config
        );

        notification.success({
            message: 'Admin',
            description: 'New admin was added successfully',
        });
        dispatch({ type: ALL_USERS_SUCCESS, payload: data.users });
    }
    catch (error) {
        notification.warning({
            message: "Admin",
            description: "Failed to add admin."
        });
        console.log(error);
        dispatch({
            type: ALL_USERS_FAIL,
            payload: "Failed to add admin"
        });
    }
};

export const deleteAdmin = (userId) => async (dispatch) => {
    try {
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': `bearer ${localStorage.getItem('token')}`,
            },
        }
        const { data } = await axios.delete(`${getServerURL()}/api/v1/admin/deleteUser/${userId}`, config);
        notification.success({
            message: 'Admin',
            description: 'An admin was deleted successfully',
        });
        dispatch({
            type: ALL_USERS_SUCCESS,
            payload: data.users,
        });
    }
    catch (error) {
        notification.warning({
            message: 'Admin',
            description: 'Failed to delete admin',
        });
        console.log(error);
        dispatch({
            type: ALL_USERS_FAIL,
            payload: "Failed to delete admin",
        });
    }
};

export const registerDeveloper = (userData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': `bearer ${localStorage.getItem('token')}`,
            },
        }
        const { data } = await axios.post(
            `${getServerURL()}/api/v1/admin/addDeveloper`,
            userData,
            config
        );

        notification.success({
            message: 'Developer',
            description: 'New developer was added successfully',
        });

        dispatch({
            type: ALL_DEVELOPERS_SUCCESS,
            payload: data.developers,
        });
    }
    catch (error) {
        notification.warning({
            message: "Developer",
            description: "Failed to add developer."
        });
        console.log(error);
    }
};

export const deleteDeveloper = (developerId) => async (dispatch) => {
    try {
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': `bearer ${localStorage.getItem('token')}`,
            },
        }
        const { data } = await axios.delete(`${getServerURL()}/api/v1/admin/deleteDeveloper/${developerId}`, config);
        notification.success({
            message: 'Developer',
            description: 'An developer was deleted successfully',
        });
        dispatch({
            type: ALL_DEVELOPERS_SUCCESS,
            payload: data.developers,
        });
    }
    catch (error) {
        notification.warning({
            message: 'Developer',
            description: 'Failed to delete developer',
        });
        console.log(error);
    }
};

export const activateUser = (userId, activeData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'Authorization': `bearer ${localStorage.getItem('token')}`,
            },
        }
        const { data } = await axios.put(`${getServerURL()}/api/v1/admin/activateUser/${userId}`, activeData, config);
        notification.success({
            message: 'User',
            description: `A user was ${activeData.active ? "activated" : "deactivated"} successfully`,
        });
        dispatch({
            type: ALL_USERS_SUCCESS,
            payload: data.users,
        });
    }
    catch (error) {
        notification.warning({
            message: 'User',
            description: 'Failed to activate/deactivate user',
        });
        console.log(error);
        dispatch({
            type: ALL_USERS_FAIL,
            payload: "Failed to activate/deactivate user",
        });
    }
}

export const updatePassword = (passwords) => async (dispatch) => {
    try {
        dispatch({ type: PROFILE_LOADING });
        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `bearer ${localStorage.getItem('token')}`,
            },
        }
        const { data } = await axios.put(
            `${getServerURL()}/api/v1/password/update`,
            passwords,
            config
        );

        notification.success({
            message: 'Profile',
            description: 'Password has been updated successfully',
        });
        dispatch({
            type: PROFILE_SUCCESS,
            payload: data.success,
        });
    }
    catch (error) {
        notification.warning({
            message: 'Profile',
            description: 'Failed to update password',
        });
        console.log(error);
        dispatch({
            type: PROFILE_FAIL,
            payload: "Failed to update password",
        });
    }
};


// Forgot Password
export const forgotPassword = (email) => async (dispatch) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        }
        const { data } = await axios.post(
            `${getServerURL()}/api/v1/password/forgot`,
            email,
            config
        );

        dispatch({
            type: FORGOT_PASSWORD_SUCCESS,
            payload: data.message,
        });
    }
    catch (error) {
        console.log(error);
        dispatch({
            type: FORGOT_PASSWORD_FAIL,
            payload: "Failed to forget password",
        });
    }
};

// Reset Password
export const resetPassword = (token, passwords) => async (dispatch) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        }
        const { data } = await axios.put(
            `${getServerURL()}/api/v1/password/reset/${token}`,
            passwords,
            config
        );

        dispatch({
            type: RESET_PASSWORD_SUCCESS,
            payload: data.success,
        });
    }
    catch (error) {
        console.log(error);
        dispatch({
            type: RESET_PASSWORD_FAIL,
            payload: "Failed to reset password",
        });
    }
};

// Get All Users ---ADMIN
export const getAllUsers = () => async (dispatch) => {
    try {
        const { data } = await axios.get(`${getServerURL()}/api/v1/admin/users`, {
            headers: {
                'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        });
        dispatch({
            type: ALL_USERS_SUCCESS,
            payload: data.users,
        });
    }
    catch (error) {
        console.log(error);
        dispatch({
            type: ALL_USERS_FAIL,
            payload: "Failed to get all users",
        });
    }
};

export const getAllDevelopers = () => async (dispatch) => {
    try {
        const { data } = await axios.get(`${getServerURL()}/api/v1/admin/developers`, {
            headers: {
                'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        });
        dispatch({
            type: ALL_DEVELOPERS_SUCCESS,
            payload: data.developers,
        });
    }
    catch (error) {
        console.log(error);
        dispatch({
            type: ALL_DEVELOPERS_FAIL,
            payload: "Failed to get all developers",
        });
    }
};

export const getAllVisits = () => async (dispatch) => {
    try {
        const { data } = await axios.get(`${getServerURL()}/api/v1/admin/visit`, {
            headers: {
                'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        });
        dispatch({
            type: VISITS_SUCCESS,
            payload: data.visits,
        });
    }
    catch (error) {
        console.log(error);
        dispatch({
            type: VISITS_FAIL,
            payload: "Failed to get visit data",
        });
    }
};

export const responseContact = (messageId, description) => async (dispatch) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `bearer ${localStorage.getItem('token')}`
            },
        }
        await axios.post(
            `${getServerURL()}/api/v1/admin/responseContact`,
            { messageId, description },
            config
        );
    }
    catch (error) {
        console.log(error);
    }
}

// Clear All Errors
export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
};
