import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    Row,
    Col,
    Card,
    Table,
    // Typography,
} from "antd";
import { getAllNotifications } from "../actions/notificationAction";

// const { Title } = Typography;

const notificationTableFields = [
    {
        title: "TIME",
        dataIndex: "time",
        key: "time",
        //width: "32%",
    },
    {
        title: "RECIPIENT",
        key: "recipient",
        dataIndex: "recipient",
    },
    {
        title: "TITLE",
        dataIndex: "title",
        key: "title",
        //width: "30%",
    },
    {
        title: "CATEGORY",
        dataIndex: "category",
        key: "category",
    },
    {
        title: "DESCRIPTION",
        dataIndex: "description",
        key: "description",
    },
];

function Notification() {
    const dispatch = useDispatch();
    const { notifications } = useSelector(state => state.notifications);
    console.log(notifications);

    useEffect(() => {
        dispatch(getAllNotifications());
    }, [dispatch]);

    const notificationTableData = notifications?.map((item, index) => {
        return {
            key: index,
            time: (
                <>
                    <div className="author-role">
                        <span>{new Date(item.time).toLocaleString()}</span>
                    </div>
                </>
            ),
            recipient: (
                <>
                    <div className="author-role">
                        <span>{item.recipient === "all" ? "All" : item.recipient === "admin" ? "Admin" : item.recipient}</span>
                    </div>
                </>
            ),
            title: (
                <>
                    <div className="author-role">
                        <span>{item.title}</span>
                    </div>
                </>
            ),
            category: (
                <>
                    <div className="author-role">
                        <span>{item.category}</span>
                    </div>
                </>
            ),
            description: (
                <>
                    <div className="author-role">
                        <span>{item.description}</span>
                    </div>
                </>
            ),
        };
    });
    return (
        <>
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs={24} xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            title="Notification List">
                            <div className="table-responsive">
                                <Table
                                    columns={notificationTableFields}
                                    dataSource={notificationTableData}
                                    pagination={true}
                                    className="ant-border-space" />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Notification;
