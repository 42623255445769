import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    Avatar,
    Typography,
} from "antd";
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { getAdminWallet, getAdminWalletCurrencies } from "../actions/financeAction";

const { Title } = Typography;

const balanceTableFields = [
    {
        title: "CURRENCY",
        dataIndex: "currency",
        key: "currency",
        // width: "32%",
    },
    {
        title: "BALANCE",
        dataIndex: "balance",
        key: "balance",
    },
];

function WalletDetail() {
    const dispatch = useDispatch();
    const { adminWallet, currencies } = useSelector(state => state.adminWallet);

    useEffect(() => {
        dispatch(getAdminWallet());
        dispatch(getAdminWalletCurrencies("56"));
    }, [dispatch]);

    const [ activeChain, setActiveChain ] = useState("56");

    const onChangeActiveChain = (e) => {
        if (e.target.value === "chain_eth"){
            setActiveChain("1");
            dispatch(getAdminWalletCurrencies("1"));
        }
        else if (e.target.value === "chain_bsc") {
            setActiveChain("56");
            dispatch(getAdminWalletCurrencies("56"));
        }
        else if (e.target.value === "chain_bsc_testnet") {
            setActiveChain("97");
            dispatch(getAdminWalletCurrencies("97"));
        }
    }
    
    const walletAddress = (adminWallet && adminWallet.wallet) ? adminWallet.wallet.slice(0, 6) + "..." + adminWallet.wallet.slice(38) : "";
    const balanceTableData = currencies[activeChain]?.map((token, index) => {
        return {
            key: index,
            currency: (
                <>
                    <Avatar.Group>
                        <Avatar
                            className="shape-avatar"
                            shape="square"
                            size={40}
                            src={token.logoURI} />
                        <div className="avatar-info">
                            <Title level={5}>{token.currencySymbol}</Title>
                        </div>
                    </Avatar.Group>{" "}
                </>
            ),
            balance: (
                <>
                    <div className="author-role">
                        <Title level={5}>{token.balance}</Title>
                    </div>
                </>
            ),
        };
    });

    return (
        <>
            <div className="tabled">
                <Row className="rowgap-vbox" gutter={[24, 0]}>
                    <Col xs={24} xl={24}>
                        <Card bordered={false} className="criclebox mb-24">
                            <div className="number">
                                <Row align="middle" gutter={[24, 0]}>
                                    <Col xs={24}>
                                        <span>Wallet Address</span>
                                        <Title level={3}>
                                            {walletAddress}
                                        </Title>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col xs={24} xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            title="Balances by Currency"
                            extra={
                                <>
                                    <Radio.Group onChange={onChangeActiveChain} defaultValue="chain_bsc">
                                        <Radio.Button value="chain_eth">Ethereum</Radio.Button>
                                        <Radio.Button value="chain_bsc">BNB Smart Chain</Radio.Button>
                                        <Radio.Button value="chain_bsc_testnet">BNB Smart Chain Testnet</Radio.Button>
                                    </Radio.Group>
                                </>
                            }>
                            <div className="table-responsive">
                                <Table
                                    columns={balanceTableFields}
                                    dataSource={balanceTableData}
                                    pagination={true}
                                    className="ant-border-space" />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default WalletDetail;
