import {
    NOTIFICATIONS_SUCCESS,
    NOTIFICATIONS_FAIL,
    NOTIFICATION_ADD_SUCCESS,
    NOTIFICATION_ADD_FAIL,
} from "../constants/notificationConstants";

export const notificationsReducer = (state = { notifications: [] }, { type, payload }) => {
    switch (type) {
        case NOTIFICATIONS_SUCCESS:
            return {
                notifications: payload
            };

        case NOTIFICATION_ADD_SUCCESS:
            return {
                notifications: [
                    ...state.notifications,
                    payload,
                ]
            }
        
        case NOTIFICATION_ADD_FAIL:
        case NOTIFICATIONS_FAIL:
            return {
                error: payload
            };
        
        default:
            return state;
    }
};