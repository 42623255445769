export const POLICY_PORTIONS_SUCCESS = "POLICY_PORTIONS_SUCCESS";
export const POLICY_PORTIONS_FAIL = "POLICY_PORTIONS_FAIL";
export const POLICY_UPDATE_PORTION_SUCCESS = "POLICY_UPDATE_PORTION_SUCCESS";
export const POLICY_UPDATE_PORTION_FAIL = "POLICY_UPDATE_PORTION_FAIL";
export const POLICY_DELETE_PORTION_SUCCESS = "POLICY_DELETE_PORTION_SUCCESS";
export const POLICY_DELETE_PORTION_FAIL = "POLICY_DELETE_PORTION_FAIL";
export const POLICY_FEES_SUCCESS = "POLICY_FEES_SUCCESS";
export const POLICY_FEES_FAIL = "POLICY_FEES_FAIL";
export const POLICY_UPDATE_FEE_SUCCESS = "POLICY_UPDATE_FEE_SUCCESS";
export const POLICY_UPDATE_FEE_FAIL = "POLICY_UPDATE_FEE_FAIL";
export const POLICY_DELETE_FEE_SUCCESS = "POLICY_DELETE_FEE_SUCCESS";
export const POLICY_DELETE_FEE_FAIL = "POLICY_DELETE_FEE_FAIL";
