import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Row,
    Col,
    Card,
    Button,
    Form,
    Input,
    Descriptions,
    Avatar,
    Alert,
    // Radio,
    // Switch,
    // Upload,
} from "antd";

import {
    FacebookOutlined,
    TwitterOutlined,
    InstagramOutlined,
} from "@ant-design/icons";

import { updatePassword } from "../actions/userAction";
import BgProfile from "../assets/images/bg-profile.jpg";
import profileAvatar from "../assets/images/defaultAvatar_enabled.png";

function Profile() {
    const dispatch = useDispatch();
    const { user }  = useSelector(state => state.user);
    const { loading } = useSelector(state => state.profile);
    const [ warnText, setWarnText ] = useState("");

    const onSetPassword = (data) => {
        if (data.newPassword.length < 8) {
            setWarnText("Password should be 8 characters at least!");
            return;
        }

        if (data.newPassword !== data.confirmNewPassword) {
            setWarnText(`Please confirm new password`);
            return;
        }

        setWarnText("");

        const formData = new FormData();
        formData.set("oldPassword", data.oldPassword);
        formData.set("newPassword", data.newPassword);
        dispatch(updatePassword(formData));
    };

    const onFailedToSetPassword = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    // const pencil = [
    //     <svg
    //         width="20"
    //         height="20"
    //         viewBox="0 0 20 20"
    //         fill="none"
    //         xmlns="http://www.w3.org/2000/svg"
    //         key={0}>
    //         <path
    //             d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
    //             className="fill-gray-7" />
    //         <path
    //             d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
    //             className="fill-gray-7" />
    //     </svg>,
    // ];

    return (
        <>
            <div
                className="profile-nav-bg"
                style={{ backgroundImage: "url(" + BgProfile + ")" }} />

            <Card
                className="card-profile-head"
                bodyStyle={{ display: "none" }}
                title={
                    <Row justify="space-between" align="middle" gutter={[24, 0]}>
                        <Col span={24} md={12} className="col-info">
                            <Avatar.Group>
                                <Avatar size={74} shape="square" src={ profileAvatar } />
                                <div className="avatar-info">
                                    <h4 className="font-semibold m-0">{ user ? user.name : "Unknown" }</h4>
                                    <p>{ user ? user.email : "Unknown" }</p>
                                </div>
                            </Avatar.Group>
                        </Col>
                    </Row>
                } />

            <Row gutter={[24, 0]}>
                <Col span={24} md={12} className="mb-24">
                    <Card
                        bordered={false}
                        title={<h6 className="font-semibold m-0">Profile Information</h6>}
                        className="header-solid h-full card-profile-information"
                        bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}>
                        <Descriptions>
                            <Descriptions.Item label="Full Name" span={3}>
                                {user ? user.name : "Unknown"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Email" span={3}>
                                {user ? user.email : "Unknown"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Mobile" span={3}>
                                {user ? user.phoneNumber : "Unknown"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Location" span={3}>
                                {user ? user.location : "Unknown"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Social" span={3}>
                                <a href="#pablo" className="mx-5 px-5">
                                    {<TwitterOutlined />}
                                </a>
                                <a href="#pablo" className="mx-5 px-5">
                                    {<FacebookOutlined style={{ color: "#344e86" }} />}
                                </a>
                                <a href="#pablo" className="mx-5 px-5">
                                    {<InstagramOutlined style={{ color: "#e1306c" }} />}
                                </a>
                            </Descriptions.Item>
                        </Descriptions>
                    </Card>
                </Col>
                <Col span={24} md={12} className="mb-24 ">
                    <Card
                        bordered={false}
                        className="header-solid h-full"
                        title={<h6 className="font-semibold m-0">Change Password</h6>}>
                        <ul className="list settings-list">
                            <li>
                                <Form
                                    onFinish={onSetPassword}
                                    onFinishFailed={onFailedToSetPassword}
                                    layout="vertical"
                                    className="row-col">
                                    {
                                        warnText !== "" && 
                                        <Form.Item>
                                            <Alert message={warnText} type="warning" />
                                        </Form.Item>
                                    }
                                    <Form.Item
                                        className="username"
                                        label="Old Password"
                                        name="oldPassword"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please input your old password!",
                                            },
                                        ]}>
                                        <Input.Password placeholder="Old Password" />
                                    </Form.Item>

                                    <Form.Item
                                        className="username"
                                        label="New Password"
                                        name="newPassword"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please input new password!",
                                            },
                                        ]}>
                                        <Input.Password placeholder="New Password" />
                                    </Form.Item>

                                    <Form.Item
                                        className="username"
                                        label="Confirm New Password"
                                        name="confirmNewPassword"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please confirm new password!",
                                            },
                                        ]}>
                                        <Input.Password placeholder="Confirm New Password" />
                                    </Form.Item>

                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={loading}
                                            style={{ width: "100%" }}>
                                        SAVE
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </li>
                        </ul>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default Profile;
