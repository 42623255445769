export const ALL_BOTS_SUCCESS = "ALL_BOTS_SUCCESS";
export const ALL_BOTS_FAIL = "ALL_BOTS_FAIL";
export const NEW_BOT_SUCCESS = "NEW_BOT_SUCCESS";
export const NEW_BOT_FAIL = "NEW_BOT_FAIL";
export const UPDATE_BOT_SUCCESS = "UPDATE_BOT_SUCCESS";
export const UPDATE_BOT_FAIL = "UPDATE_BOT_FAIL";
export const DELETE_BOT_SUCCESS = "DELETE_BOT_SUCCESS";
export const DELETE_BOT_FAIL = "DELETE_BOT_FAIL";
export const START_BOT_SUCCESS = "START_BOT_SUCCESS";
export const START_BOT_FAIL = "START_BOT_FAIL";
export const STOP_BOT_SUCCESS = "STOP_BOT_SUCCESS";
export const STOP_BOT_FAIL = "STOP_BOT_FAIL";
export const ADD_BOT_LOG = "ADD_BOT_LOG";
export const CLEAR_BOT_LOG = "CLEAR_BOT_LOG";
export const ALL_BOT_TRADES_SUCCESS = "ALL_BOT_TRADES_SUCCESS";
export const ALL_BOT_TRADES_FAIL = "ALL_BOT_TRADES_FAIL";
export const ADD_BOT_TRADE = "ADD_BOT_TRADE";
