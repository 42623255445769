const SERVER_HOSTNAME = "api.highperformbot.com"; // window.location.hostname
const SERVER_HTTP_PORT = 443;

export const RPC_URLS = {
    "1": {
        name: "eth",
        description: "Ethereum",
        url: "https://eth.drpc.org",
        explorer: "https://etherscan.io",
    },
    "56": {
        name: "bsc",
        description: "BNB Smart Chain",
        url: "https://bsc-dataseed.binance.org",
        explorer: "https://bscscan.com",
    },
    "97": {
        name: "bsc_testnet",
        description: "BNB Smart Chain Testnet",
        url: "https://data-seed-prebsc-1-s1.binance.org:8545",
        explorer: "https://testnet.bscscan.com",
    }
};

export const getServerURL = () => {
    return `https://${SERVER_HOSTNAME}:${SERVER_HTTP_PORT}`;
}
