
import {
    POLICY_PORTIONS_SUCCESS,
    POLICY_PORTIONS_FAIL,
    POLICY_UPDATE_PORTION_SUCCESS,
    POLICY_UPDATE_PORTION_FAIL,
    POLICY_DELETE_PORTION_SUCCESS,
    POLICY_DELETE_PORTION_FAIL,
    POLICY_FEES_SUCCESS,
    POLICY_FEES_FAIL,
    POLICY_UPDATE_FEE_SUCCESS,
    POLICY_UPDATE_FEE_FAIL,
    POLICY_DELETE_FEE_SUCCESS,
    POLICY_DELETE_FEE_FAIL,
} from "../constants/policyConstants";

export const policyReducer = (state = { portions: [], fees: [] }, { type, payload }) => {
    switch (type) {
        case POLICY_UPDATE_PORTION_SUCCESS:
        case POLICY_DELETE_PORTION_SUCCESS:
        case POLICY_PORTIONS_SUCCESS:
            return {
                ...state,
                portions: payload
            };
        
        case POLICY_UPDATE_FEE_SUCCESS:
        case POLICY_DELETE_FEE_SUCCESS:
        case POLICY_FEES_SUCCESS:
            return {
                ...state,
                fees: payload
            };
        
        case POLICY_UPDATE_PORTION_FAIL:
        case POLICY_DELETE_PORTION_FAIL:
        case POLICY_PORTIONS_FAIL:
            return {
                ...state,
                portions: [],
                error: payload
            };

        case POLICY_UPDATE_FEE_FAIL:
        case POLICY_DELETE_FEE_FAIL:
        case POLICY_FEES_FAIL:
            return {
                ...state,
                fees: [],
                error: payload
            };
        
        default:
            return state;
    }
};