import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
    Layout,
    Menu,
    Card,
    Avatar,
    Skeleton,
    List,
    Space,
    Input,
    Button,
    Typography,
} from 'antd';

import { responseContact } from '../actions/userAction';
import avatarEnabled from "../assets/images/defaultAvatar_enabled.png";

const { Content, Sider } = Layout;
const { TextArea } = Input;
const { Title } = Typography;

function Messages() {
    const dispatch = useDispatch();
    const { messages } = useSelector(state => state.adminMessages);
    const [ selectedIndex, setSelectedIndex ] = useState(0);
    const [ description, setDescription ] = useState("");
    const messageList = (messages && messages.length > 0) ? messages[selectedIndex].messages : [];
    const subject = (messages && messages.length > 0) ? messages[selectedIndex].subject : "No Subject";

    const onSelectMenu = (e) => {
        setSelectedIndex(parseInt(e.key));
    }

    const onChangeDescription = (e) => {
        setDescription(e.target.value);
    }

    const onSendReply = () => {
        if (messages.length > 0) {
            const messageId = messages[selectedIndex]._id;
            dispatch(responseContact(messageId, description));
        }
    }

    return (
        <Layout style={{ marginLeft: "0px" }}>
            <Sider theme="light" width="280px">
                <Card bordered={false} className="tablespace h-full" style={{ borderRadius: '0px' }} title="All Messages">
                    <Menu theme="light" mode="inline" defaultSelectedKeys={["0"]} onSelect={onSelectMenu}>
                        {
                            messages?.map((item, index) => {
                                return (
                                    <Menu.Item key={index}>
                                        <Avatar.Group>
                                            <Avatar
                                                className="shape-avatar"
                                                shape="square"
                                                size={40}
                                                src={avatarEnabled} />
                                            <div className="avatar-info">
                                                <Title level={5}>{item.name}</Title>
                                                <p>{item.email}</p>
                                            </div>
                                        </Avatar.Group>
                                    </Menu.Item>
                                );
                            })
                        }
                    </Menu>
                </Card>
            </Sider>
            <Layout style={{ marginLeft: "0px" }}>
                <Content style={{ margin: '0px 1px' }}>
                    <Card bordered={false} className="tablespace h-full" style={{ borderRadius: '0px' }} title={subject}>
                        <div
                            id="scrollableDiv"
                            style={{
                                height: '70vh',
                                overflow: 'auto',
                                padding: '0 16px',
                                // margin: '10px',
                                borderBottom: '1px solid rgba(140, 140, 140, 0.35)',
                            }}>
                            <InfiniteScroll
                                dataLength={messageList.length}
                                hasMore={false}
                                next={() => {}}
                                loader={ <Skeleton avatar paragraph={{ rows: 1, }} active /> }
                                scrollableTarget="scrollableDiv">
                                <List
                                    dataSource={messageList}
                                    renderItem={(item) => (
                                        <List.Item key={item.email} style={{ alignItems: "unset" }}>
                                            <List.Item.Meta
                                                avatar={<Avatar src={avatarEnabled} />}
                                                title={item.from.name}
                                                description={<div dangerouslySetInnerHTML={{ __html: item.description }} />} />
                                            <div>{new Date(item.time).toLocaleString()}</div>
                                        </List.Item>
                                    )} />
                            </InfiniteScroll>
                        </div>
                        <Space.Compact block direction="horizontal" align="end">
                            <TextArea rows={4} style={{ margin: "10px 0px" }} onChange={onChangeDescription} />
                            <Button type="primary" style={{ margin: "10px 5px", width: "100px" }} block onClick={onSendReply}>
                                Send
                            </Button>
                        </Space.Compact>
                    </Card>
                </Content>
            </Layout>
        </Layout>
    );
}

export default Messages;
