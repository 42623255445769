import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    Modal,
    Form,
    Input,
    Button,
    Avatar,
    Typography,
} from "antd";
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { ToTopOutlined } from "@ant-design/icons";
import { getAllUsers, registerAdmin, deleteAdmin, activateUser } from "../actions/userAction";

import avatarEnabled from "../assets/images/defaultAvatar_enabled.png";
import avatarDisabled from "../assets/images/defaultAvatar_disabled.png";

const { Title } = Typography;

const userTableFields = [
    {
        title: "NAME",
        dataIndex: "name",
        key: "name",
        width: "32%",
    },
    // {
    //     title: "GENDER",
    //     dataIndex: "gender",
    //     key: "gender",
    // },
    {
        title: "ROLE",
        dataIndex: "role",
        key: "role",
    },
    {
        title: "CREATED AT",
        key: "created",
        dataIndex: "created",
    },
    {
        title: "STATUS",
        key: "status",
        dataIndex: "status",
    },
    {
        title: "ACTION",
        key: "action",
        dataIndex: "action",
    },
];

function Users() {
    const { users } = useSelector(state => state.users);
    const { user }  = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [ userViewMode, setUserViewMode ] = useState("user_all");

    useEffect(() => {
        dispatch(getAllUsers());
    }, [dispatch]);

    const onChangeUserViewMode = (e) => {
        setUserViewMode(e.target.value);
    };

    const adminList = users?.filter(item => item.role === "admin" || item.role === "super");
    const userList = users?.filter(item => {
        if (item.role === "user") {
            if (userViewMode === "user_all")
                return true;
            if (userViewMode === "user_verified")
                return item.status === "verified";
            if (userViewMode === "user_pending")
                return item.status === "created";
            if (userViewMode === "user_suspended")
                return item.status === "suspended";
        }
        return false;
    });

    const adminTableData = adminList.map((item, index) => {
        return {
            key: index,
            name: (
                <>
                    <Avatar.Group>
                        <Avatar
                            className="shape-avatar"
                            shape="square"
                            size={40}
                            src={avatarEnabled} />
                        <div className="avatar-info">
                            <Title level={5}>{item.name}</Title>
                            <p>{item.email}</p>
                        </div>
                    </Avatar.Group>{" "}
                </>
            ),
            role: (
                <>
                    <div className="author-role">
                        <Title level={5}>{item.role}</Title>
                    </div>
                </>
            ),
            status: (
                <>
                    <Button type="primary" className="tag-primary">
                    Verified
                    </Button>
                </>
            ),
            created: (
                <>
                    <div className="ant-employed">
                        <span>{new Date(item.createdAt).toLocaleString()}</span>
                    </div>
                </>
            ),
            action: (
                <>
                    <div className="ant-action">
                        <Button.Group>
                            { user && <Button disabled={user.role !== "super" || item.role === "super"} onClick={() => onDeleteAdmin(item)}>Delete</Button> }
                        </Button.Group>
                    </div>
                </>
            )
        };
    });
    const userTableData = userList.map((item, index) => {
        return {
            key: index,
            name: (
                <>
                    <Avatar.Group>
                        <Avatar
                            className="shape-avatar"
                            shape="square"
                            size={40}
                            src={item.status === "verified" ? avatarEnabled : avatarDisabled} />
                        <div className="avatar-info">
                            <Title level={5}>{item.name}</Title>
                            <p>{item.email}</p>
                        </div>
                    </Avatar.Group>{" "}
                </>
            ),
            role: (
                <>
                    <div className="author-role">
                        <Title level={5}>{item.role}</Title>
                    </div>
                </>
            ),
            status: (
                <>
                    {
                        (item.status === "verified") ?
                        (<Button type="primary" className="tag-primary">Verified</Button>) : 
                        (item.status === "created") ?
                        (<Button type="secondary" className="tag-secondary">Pending</Button>) :
                        (<Button type="secondary" className="tag-secondary">Suspended</Button>)
                    }
                </>
            ),
            created: (
                <>
                    <div className="ant-employed">
                        <span>{new Date(item.createdAt).toLocaleString()}</span>
                    </div>
                </>
            ),
            action: (
                <>
                    <div className="ant-action">
                        <Button.Group>
                            <Button onClick={() => onActiveUser(item)}>{ item.status === "verified" ? "Deactivate" : "Activate" }</Button>
                        </Button.Group>
                    </div>
                </>
            )
        };
    });

    const [newAdminForm] = Form.useForm();
    const [isOpenNewAdmin, setIsOpenNewAdmin] = useState(false);
    const [isOpenDeleteAdmin, setIsOpenDeleteAdmin] = useState(false);
    const [isOpenActiveUser, setIsOpenActiveUser] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    const onActiveUser = (item) => {
        setSelectedUser(item);
        setIsOpenActiveUser(true);
    }

    const onHandleActiveUser = () => {
        if (selectedUser) {
            dispatch(activateUser(selectedUser._id, { active: selectedUser.status !== "verified" }));
            setSelectedUser(null);
        }
        setIsOpenActiveUser(false);
    };

    const onCancelActiveUser = () => {
        setIsOpenActiveUser(false);
    };

    const onDeleteAdmin = (item) => {
        setSelectedUser(item);
        setIsOpenDeleteAdmin(true);
    }

    const showNewAdminModal = () => {
        setIsOpenNewAdmin(true);
    };

    const onHandleAddAdmin = async () => {
        const valid = await newAdminForm.validateFields();
        if (valid.errorFields)
            return;
        
        const formData = new FormData();
        formData.set("name", newAdminForm.getFieldValue("i_name"));
        formData.set("email", newAdminForm.getFieldValue("i_email"));
        formData.set("password", newAdminForm.getFieldValue("i_password"));
        dispatch(registerAdmin(formData));
        setIsOpenNewAdmin(false);
    };

    const onCancelAddAdmin = () => {
        setIsOpenNewAdmin(false);
    };

    const onHandleDeleteAdmin = () => {
        if (selectedUser) {
            dispatch(deleteAdmin(selectedUser._id));
            setSelectedUser(null);
        }
        setIsOpenDeleteAdmin(false);
    };

    const onCancelDeleteAdmin = () => {
        setIsOpenDeleteAdmin(false);
    };

    return (
        <>
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs={24} xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            title="Admin List">
                            <div className="table-responsive">
                                <Table
                                    columns={userTableFields}
                                    dataSource={adminTableData}
                                    pagination={false}
                                    className="ant-border-space" />
                            </div>
                            <div className="uploadfile pb-15 shadow-none">
                                <Button
                                    type="dashed"
                                    className="ant-full-box"
                                    disabled={user && user.role !== "super"}
                                    icon={<ToTopOutlined />}
                                    onClick={showNewAdminModal}>
                                    Add New Admin...
                                </Button>
                            </div>
                            <Modal title="Delete Admin" open={isOpenDeleteAdmin} onOk={onHandleDeleteAdmin} onCancel={onCancelDeleteAdmin}>
                                <p>Are you sure you want to delete admin '{selectedUser ? selectedUser.name : ''}'?</p>
                            </Modal>
                            <Modal title="Add New Admin" open={isOpenNewAdmin} onOk={onHandleAddAdmin} onCancel={onCancelAddAdmin}>
                                <Form
                                    form={newAdminForm}
                                    layout="vertical"
                                    className="row-col">
                                    <Form.Item
                                        className="username"
                                        label="Name"
                                        name="i_name"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please input user name!",
                                            },
                                        ]}>
                                        <Input placeholder="Type your user name" />
                                    </Form.Item>

                                    <Form.Item
                                        className="username"
                                        label="Email"
                                        name="i_email"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please input e-mail!",
                                            },
                                        ]}>
                                        <Input placeholder="Type your e-mail" />
                                    </Form.Item>

                                    <Form.Item
                                        className="username"
                                        label="Password"
                                        name="i_password"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please input e-mail!",
                                            },
                                        ]}>
                                        <Input.Password placeholder="Type your password" />
                                    </Form.Item>
                                </Form>
                            </Modal>
                        </Card>
                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            title="User List"
                            extra={
                                <>
                                    <Radio.Group onChange={onChangeUserViewMode} defaultValue="user_all">
                                        <Radio.Button value="user_all">All</Radio.Button>
                                        <Radio.Button value="user_verified">Verified</Radio.Button>
                                        <Radio.Button value="user_pendig">Pending</Radio.Button>
                                        <Radio.Button value="user_suspended">Suspended</Radio.Button>
                                    </Radio.Group>
                                </>
                            }>
                            <div className="table-responsive">
                                <Table
                                    columns={userTableFields}
                                    dataSource={userTableData}
                                    pagination={false}
                                    className="ant-border-space" />
                            </div>
                            <Modal title="Activate/Deactivate User" open={isOpenActiveUser} onOk={onHandleActiveUser} onCancel={onCancelActiveUser}>
                                <p>Are you sure you want to {selectedUser && selectedUser.status === "verified" ? "deactivate" : "activate"} user '{selectedUser ? selectedUser.name : ''}'?</p>
                            </Modal>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Users;
