import {
    USER_LOADING,
    USER_LOADED,
    USER_UNLOADED,
    USER_ERROR,
    CLEAR_ERRORS,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAIL,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL,
    PROFILE_LOADING,
    PROFILE_SUCCESS,
    PROFILE_FAIL,
    ALL_USERS_SUCCESS,
    ALL_USERS_FAIL,
    ALL_DEVELOPERS_SUCCESS,
    ALL_DEVELOPERS_FAIL,
    ALL_MESSAGES_SUCCESS,
    ALL_MESSAGES_FAIL,
    VISITS_SUCCESS,
    VISITS_FAIL,
    ADD_NEW_MESSAGE,
    ADD_NEW_SUB_MESSAGE,
    ALL_LICENSES_SUCCESS,
    ALL_LICENSES_FAIL,
    ADD_NEW_LICENSE,
} from '../constants/userConstants';

export const userReducer = (state = { user: {} }, { type, payload }) => {
    switch (type) {
        case USER_LOADING:
            return {
                loading: true,
                isAuthenticated: false,
            };
        
        case USER_LOADED:
            if (state.websocket)
                state.websocket.close();
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                user: payload.user,
                websocket: payload.websocket,
            };

        case USER_UNLOADED:
            if (state.websocket)
                state.websocket.close();
            return {
                loading: false,
                user: {},
                websocket: null,
                isAuthenticated: false,
            };
        
        case USER_ERROR:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                user: {},
                error: payload,
            };
            
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
            
        default:
            return state;
    }
};

export const visitsReducer = (state = { visits: [] }, { type, payload }) => {
    switch (type) {
        case VISITS_SUCCESS:
            return {
                visits: payload,
            };

        case VISITS_FAIL:
            return {
                visits: [],
            };

        default:
            return state;
    }
}

export const profileReducer = (state = {}, { type, payload }) => {
    switch (type) {
        case PROFILE_LOADING:
            return {
                ...state,
                loading: true,
            };

        case PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: payload,
            };

        case PROFILE_FAIL:
            return {
                ...state,
                loading: false,
                error: payload,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

export const allUsersReducer = (state = { users: [] }, { type, payload }) => {
    switch (type) {
        case ALL_USERS_SUCCESS:
            return {
                ...state,
                users: payload,
            };

        case ALL_USERS_FAIL:
            return {
                ...state,
                error: payload,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

export const allDevelopersReducer = (state = { developers: [] }, { type, payload }) => {
    switch (type) {
        case ALL_DEVELOPERS_SUCCESS:
            return {
                ...state,
                developers: payload,
            };

        case ALL_DEVELOPERS_FAIL:
            return {
                ...state,
                error: payload,
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

export const adminMessageReducer = (state = { messages: [] }, { type, payload }) => {
    switch (type) {
        case ALL_MESSAGES_SUCCESS:
            return {
                messages: payload,
            };

        case ALL_MESSAGES_FAIL:
            return {
                ...state,
                error: payload,
            };

        case ADD_NEW_MESSAGE:
            return {
                messages: [
                    ...state.messages,
                    payload,
                ]
            };

        case ADD_NEW_SUB_MESSAGE:
            const message = state.messages.find(item => item._id === payload.messageId);
            if (!message)
                return state;
            
            message.messages = [
                ...message.messages,
                payload.subMessage
            ];
            return {
                messages: [
                    ...state.messages
                ]
            };
            
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

export const allLicensesReducer = (state = { licenses: [] }, { type, payload }) => {
    switch (type) {
        case ALL_LICENSES_SUCCESS:
            return {
                licenses: payload,
            };

        case ALL_LICENSES_FAIL:
            return {
                ...state,
                error: payload,
            };

        case ADD_NEW_LICENSE:
            return {
                licenses: [
                    ...state.licenses,
                    payload,
                ]
            };
            
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

export const forgotPasswordReducer = (state = {}, { type, payload }) => {
    switch (type) {
        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                message: payload,
            };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                success: payload,
            };
        case FORGOT_PASSWORD_FAIL:
        case RESET_PASSWORD_FAIL:
            return {
                ...state,
                error: payload,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
};
