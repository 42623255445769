import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { forgotPasswordReducer, profileReducer, userReducer, allUsersReducer, allDevelopersReducer, visitsReducer, adminMessageReducer, allLicensesReducer } from './reducers/userReducer';
import { allBotsReducer, botLogsReducer, allBotTradesReducer } from './reducers/botReducer';
import { adminWalletReducer, allUserTransactionsReducer, allBotTransactionsReducer, botBalancesReducer, userRewardsReducer } from './reducers/financeReducer';
import { notificationsReducer } from './reducers/notificationReducer';
import { policyReducer } from './reducers/policyReducer';

const reducer = combineReducers({
    user: userReducer,
    profile: profileReducer,
    forgotPassword: forgotPasswordReducer,
    users: allUsersReducer,
    userTransactions: allUserTransactionsReducer,
    userRewards: userRewardsReducer,
    developers: allDevelopersReducer,
    licenses: allLicensesReducer,
    bots: allBotsReducer,
    botLogs: botLogsReducer,
    botBalances: botBalancesReducer,
    botTransactions: allBotTransactionsReducer,
    botTrades: allBotTradesReducer,
    adminWallet: adminWalletReducer,
    adminMessages: adminMessageReducer,
    visits: visitsReducer,
    notifications: notificationsReducer,
    policy: policyReducer,
});

let initialState = {
};

const middleware = [thunk];

const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
);

export default store;