import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
    Row,
    Col,
    Card,
    Statistic,
    Button,
    // List,
    Form,
    Input,
    Table,
    Avatar,
    Modal,
    Typography,
} from "antd";

import { PlusOutlined } from "@ant-design/icons";
import { ToTopOutlined } from "@ant-design/icons";
import mastercard from "../assets/images/mastercard-logo.png";
//import paypal from "../assets/images/paypal-logo-2.png";
//import visa from "../assets/images/visa-logo.png";
import { getAdminWallet, withdrawFromBot, withdrawFromAllBots } from "../actions/financeAction";
import { getAllUsers, getAllDevelopers, registerDeveloper, deleteDeveloper } from '../actions/userAction';
import { getAllBots } from '../actions/botAction';
import { RPC_URLS } from '../constants/server';

import avatarEnabled from "../assets/images/defaultAvatar_enabled.png";
import avatarDisabled from "../assets/images/defaultAvatar_disabled.png";

const { Title } = Typography;

const wifi = [
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 22.5 20.625"
        key={0}>
        <g id="wifi" transform="translate(0.75 0.75)">
            <circle
                id="Oval"
                cx="1.5"
                cy="1.5"
                r="1.5"
                transform="translate(9 16.875)"
                fill="#fff" />
            <path
                id="Path"
                d="M0,1.36a6.377,6.377,0,0,1,7.5,0"
                transform="translate(6.75 11.86)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="1.5" />
            <path
                id="Path-2"
                data-name="Path"
                d="M14.138,2.216A12.381,12.381,0,0,0,0,2.216"
                transform="translate(3.431 6)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="1.5" />
            <path
                id="Path-3"
                data-name="Path"
                d="M0,3.294a18.384,18.384,0,0,1,21,0"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="1.5" />
        </g>
    </svg>,
];

const angle = [
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
        key={0}>
        <g id="bank" transform="translate(0.75 0.75)">
            <path
                id="Shape"
                transform="translate(0.707 9.543)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="1.5" />
            <path
                id="Path"
                d="M10.25,0,20.5,9.19H0Z"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="1.5" />
            <path
                id="Path-2"
                data-name="Path"
                d="M0,.707H20.5"
                transform="translate(0 19.793)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="1.5" />
        </g>
    </svg>,
];

const botBalanceTableFields = [
    {
        title: "NAME",
        dataIndex: "name",
        key: "name",
        //width: "32%",
    },
    {
        title: "CHAIN",
        dataIndex: "chain",
        key: "chain",
    },
    {
        title: "BALANCE",
        dataIndex: "balance",
        key: "balance",
    },
    {
        title: "ACTION",
        key: "action",
        dataIndex: "action",
    },
];

const userTransactionTableFields = [
    {
        title: "DATE/TIME",
        key: "time",
        dataIndex: "time",
    },
    {
        title: "NAME",
        dataIndex: "name",
        key: "name",
        //width: "32%",
    },
    {
        title: "CHAIN",
        dataIndex: "chain",
        key: "chain",
        //width: "32%",
    },
    {
        title: "AMOUNT",
        key: "amount",
        dataIndex: "amount",
    },
    {
        title: "BOT",
        dataIndex: "bot",
        key: "bot",
    },
    {
        title: "TXN",
        key: "txn",
        dataIndex: "txn",
    },
];

const botTransactionTableFields = [
    {
        title: "DATE/TIME",
        dataIndex: "time",
        key: "time",
    },
    {
        title: "NAME",
        dataIndex: "name",
        key: "name",
        //width: "32%",
    },
    {
        title: "CHAIN",
        dataIndex: "chain",
        key: "chain",
        //width: "32%",
    },
    {
        title: "AMOUNT",
        key: "amount",
        dataIndex: "amount",
    },
    {
        title: "TXN",
        key: "txn",
        dataIndex: "txn",
    },
];

const licenseTableFields = [
    {
        title: "EMAIL",
        dataIndex: "email",
        key: "email",
    },
    {
        title: "CODE",
        dataIndex: "code",
        key: "code",
    },
    {
        title: "DAYS",
        dataIndex: "days",
        key: "days",
    },
    {
        title: "START DATE",
        dataIndex: "startDate",
        key: "startDate",
    },
    {
        title: "END DATE",
        dataIndex: "endDate",
        key: "endDate",
    },
    {
        title: "CHAIN",
        dataIndex: "chain",
        key: "chain",
    },
    {
        title: "TXN",
        dataIndex: "txn",
        key: "txn",
    },
];

const rewardTableFields = [
    {
        title: "NAME",
        dataIndex: "name",
        key: "name",
        //width: "32%",
    },
    {
        title: "CHAIN",
        key: "chain",
        dataIndex: "chain",
    },
    {
        title: "REWARD",
        key: "reward",
        dataIndex: "reward",
    },
    {
        title: "DEPOSITED",
        key: "deposited",
        dataIndex: "deposited",
    },
    {
        title: "TOTAL",
        key: "total",
        dataIndex: "total",
    },
    {
        title: "BOT",
        key: "bot",
        dataIndex: "bot",
    },
];

const developerTableFields = [
    {
        title: "NAME",
        dataIndex: "name",
        key: "name",
        width: "32%",
    },
    {
        title: "WALLET",
        dataIndex: "wallet",
        key: "wallet",
    },
    {
        title: "BOT",
        key: "bot",
        dataIndex: "bot",
    },
    {
        title: "CHAIN",
        key: "chain",
        dataIndex: "chain",
    },
    {
        title: "PENDING",
        key: "pending",
        dataIndex: "pending",
    },
    {
        title: "PAID",
        key: "paid",
        dataIndex: "paid",
    },
    {
        title: "ACTION",
        key: "action",
        dataIndex: "action",
    },
];

function Finance() {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user);
    const { adminWallet } = useSelector(state => state.adminWallet);
    const { transactions: userTransactions } = useSelector(state => state.userTransactions);
    const { transactions: botTransactions } = useSelector(state => state.botTransactions);
    const { licenses } = useSelector(state => state.licenses);
    const { balances } = useSelector(state => state.botBalances);
    const { rewards } = useSelector(state => state.userRewards);
    const { users } = useSelector(state => state.users);
    const { developers } = useSelector(state => state.developers);
    const { bots } = useSelector(state => state.bots);
    const { trades } = useSelector(state => state.botTrades);
    
    useEffect(() => {
        dispatch(getAdminWallet());
        dispatch(getAllUsers());
        dispatch(getAllBots());
        dispatch(getAllDevelopers());
    }, [dispatch]);

    const walletAddress = (adminWallet && adminWallet.wallet) ? adminWallet.wallet.slice(0, 6) + "..." + adminWallet.wallet.slice(38) : "";
    const reversedUserTxns = userTransactions ? [ ...userTransactions ].reverse() : [];
    const userTransactionTableData = reversedUserTxns.map((item, index) => {
        const user = users?.find(user => item.userId === user._id);
        const bot = bots?.find(bot => item.botId === bot._id);
        return {
            key: index,
            name: (
                <>
                    <Avatar.Group>
                        <Avatar
                            className="shape-avatar"
                            shape="square"
                            size={40}
                            src={user?.status === "verified" ? avatarEnabled : avatarDisabled} />
                        <div className="avatar-info">
                            <Title level={5}>{user ? user.name : "[Deleted User]"}</Title>
                            <p>{user ? user.email : item.userId}</p>
                        </div>
                    </Avatar.Group>
                    {" "}
                </>
            ),
            chain: (
                <>
                    <div className="author-role">
                        <Title level={5}>{item.chainId ? RPC_URLS[item.chainId].description : "Unknown"}</Title>
                    </div>
                </>
            ),
            amount: (
                <>
                    <div className="author-role">
                        <Title className={item.type} level={5}>
                            {item.type === "deposit" ? "+" : "-"} {item.amount}
                        </Title>
                        <a href={`${item.chainId ? RPC_URLS[item.chainId].explorer : ""}/token/${item.currency}#code`} className="text-sm" target="_blank" rel="noreferrer">{item.currencySymbol}</a>
                    </div>
                </>
            ),
            bot: (
                <>
                    <div className="author-role">
                        <Title level={5}>{bot ? bot.name : "[Deleted Bot]"}</Title>
                    </div>
                </>
            ),
            time: (
                <>
                    <div className="ant-employed">
                        <span>{new Date(item.time).toLocaleString()}</span>
                    </div>
                </>
            ),
            txn: (
                <>
                    <div className="author-role">
                        <a href={`${item.chainId ? RPC_URLS[item.chainId].explorer : ""}/tx/${item.txn}`} target="_blank" rel="noreferrer">{item.txn}</a>
                    </div>
                </>
            )
        };
    });

    const botBalanceTableData = balances?.map((item, index) => {
        const bot = bots?.find(bot => item.botId === bot._id);
        return {
            key: index,
            name: (
                <>
                    <div className="author-role">
                        <Title level={5}>{bot ? bot.name : "[Deleted Bot]"}</Title>
                    </div>
                </>
            ),
            chain: (
                <>
                    <div className="author-role">
                        <Title level={5}>{item.chainId ? RPC_URLS[item.chainId].description : "Unknown"}</Title>
                    </div>
                </>
            ),
            balance: (
                <>
                    <div className="author-role">
                        <Title level={5}>{item.balance}</Title>
                        <a href={`${item.chainId ? RPC_URLS[item.chainId].explorer : ""}/token/${item.currency}#code`} className="text-sm" target="_blank" rel="noreferrer">{item.currencySymbol}</a>
                    </div>
                </>
            ),
            action: (
                <>
                    <div className="ant-action">
                        <Button.Group>
                            <Button onClick={ () => onWithdraw(item.botId) }>Withdraw</Button>
                        </Button.Group>
                    </div>
                </>
            )
        };
    });

    const reversedBotTxns = botTransactions ? [ ...botTransactions ].reverse() : [];
    const botTransactionTableData = reversedBotTxns.map((item, index) => {
        const bot = bots?.find(bot => item.botId === bot._id);
        return {
            key: index,
            time: (
                <>
                    <div className="ant-employed">
                        <span>{new Date(item.time).toLocaleString()}</span>
                    </div>
                </>
            ),
            name: (
                <>
                    <div className="avatar-info">
                        <Title level={5}>{bot ? bot.name : "[Deleted Bot]"}</Title>
                    </div>
                </>
            ),
            chain: (
                <>
                    <div className="author-role">
                        <Title level={5}>{item.chainId ? RPC_URLS[item.chainId].description : "Unknown"}</Title>
                    </div>
                </>
            ),
            amount: (
                <>
                    <div className="author-role">
                        <Title className={item.type} level={5}>
                            {item.type === "deposit" ? "+" : "-"} {Number(item.amount).toFixed(4)}
                        </Title>
                        <a href={`${item.chainId ? RPC_URLS[item.chainId].explorer : ""}/token/${item.currency}#code`} className="text-sm" target="_blank" rel="noreferrer">{item.currencySymbol}</a>
                    </div>
                </>
            ),
            txn: (
                <>
                    <div className="author-role">
                        <a href={`${item.chainId ? RPC_URLS[item.chainId].explorer : ""}/tx/${item.txn}`} target="_blank" rel="noreferrer">{item.txn}</a>
                    </div>
                </>
            )
        };
    });

    const licenseTableData = licenses?.map((item, index) => {
        return {
            key: index,
            email: (
                <>
                    <div className="author-role">
                        <span>{item.email}</span>
                    </div>
                </>
            ),
            code: (
                <>
                    <div className="author-role">
                        <span>{item.code}</span>
                    </div>
                </>
            ),
            days: (
                <>
                    <div className="author-role">
                        <span>{item.days}</span>
                    </div>
                </>
            ),
            startDate: (
                <>
                    <div className="author-role">
                        <span>{new Date(item.startDate).toLocaleDateString()}</span>
                    </div>
                </>
            ),
            endDate: (
                <>
                    <div className="author-role">
                        <span>{new Date(item.endDate).toLocaleDateString()}</span>
                    </div>
                </>
            ),
            chain: (
                <>
                    <div className="author-role">
                        <span>{item.chainId ? RPC_URLS[item.chainId].description : "Unknown"}</span>
                    </div>
                </>
            ),
            txn: (
                <>
                    <div className="author-role">
                        <a href={`${item.chainId ? RPC_URLS[item.chainId].explorer : ""}/tx/${item.txn}`} target="_blank" rel="noreferrer">{item.txn}</a>
                    </div>
                </>
            )
        };
    });

    const userRewardTableData = rewards?.map((item, index) => {
        const botReward = item.bots[0];
        const user = users?.find(user => item.userId === user._id);
        const bot = bots?.find(bot => botReward?.botId === bot._id);
        return {
            key: index,
            name: (
                <>
                    <Link to={`/finance/user-reward/${item.userId}`}>
                        <Avatar.Group>
                            <Avatar
                                className="shape-avatar"
                                shape="square"
                                size={40}
                                src={user?.status === "verified" ? avatarEnabled : avatarDisabled} />
                            <div className="avatar-info">
                                <Title level={5}>{user ? `${user.name}` : "[Deleted User]"}</Title>
                                <p>{user ? user.email : item.userId}</p>
                            </div>
                        </Avatar.Group>{" "}
                    </Link>
                </>
            ),
            chain: (
                <>
                    <div className="author-role">
                        <Title level={5}>{botReward?.chainId ? RPC_URLS[botReward.chainId].description : "Unknown"}</Title>
                    </div>
                </>
            ),
            reward: (
                <>
                    <div className="author-role">
                        <Title level={5}>{botReward?.reward}</Title>
                        <a href={`${botReward?.chainId ? RPC_URLS[botReward.chainId].explorer : ""}/token/${botReward.currency}#code`} className="text-sm" target="_blank" rel="noreferrer">{botReward.currencySymbol}</a>
                    </div>
                </>
            ),
            deposited: (
                <>
                    <div className="author-role">
                        <Title level={5}>{botReward?.deposited}</Title>
                        <a href={`${botReward?.chainId ? RPC_URLS[botReward.chainId].explorer : ""}/token/${botReward.currency}#code`} className="text-sm" target="_blank" rel="noreferrer">{botReward.currencySymbol}</a>
                    </div>
                </>
            ),
            total: (
                <>
                    <div className="author-role">
                        <Title level={5}>{botReward?.balance}</Title>
                        <a href={`${botReward?.chainId ? RPC_URLS[botReward.chainId].explorer : ""}/token/${botReward.currency}#code`} className="text-sm" target="_blank" rel="noreferrer">{botReward.currencySymbol}</a>
                    </div>
                </>
            ),
            bot: (
                <>
                    <div className="author-role">
                        <Title level={5}>{bot ? bot.name : "[Deleted Bot]"}</Title>
                    </div>
                </>
            ),
        };
    });

    const developerTableData = developers?.map((item, index) => {
        const botReward = item.bots[0];
        const bot = bots.find(bot => bot._id === botReward?.botId);
        return {
            key: index,
            name: (
                <>
                    <Avatar.Group>
                        <Avatar
                            className="shape-avatar"
                            shape="square"
                            size={40}
                            src={avatarEnabled} />
                        <div className="avatar-info">
                            <Title level={5}>{item.name}</Title>
                            <p>{item.email}</p>
                        </div>
                    </Avatar.Group>{" "}
                </>
            ),
            wallet: (
                <>
                    <div className="author-role">
                        <Title level={5}>{item.wallet}</Title>
                    </div>
                </>
            ),
            bot: (
                <>
                    <div className="author-role">
                        <Title level={5}>{bot ? bot.name : "[Deleted Bot]"}</Title>
                    </div>
                </>
            ),
            chain: (
                <>
                    <div className="author-role">
                        <Title level={5}>{botReward?.chainId ? RPC_URLS[botReward.chainId].description : "Unknown"}</Title>
                    </div>
                </>
            ),
            pending: (
                <>
                    <div className="author-role">
                        <Title level={5}>{botReward?.pendingReward}</Title>
                        <a href={`${botReward?.chainId ? RPC_URLS[botReward.chainId].explorer : ""}/token/${botReward?.currency}#code`} className="text-sm" target="_blank" rel="noreferrer">{botReward?.currencySymbol}</a>
                    </div>
                </>
            ),
            paid: (
                <>
                    <div className="author-role">
                        <Title level={5}>{botReward?.paidReward}</Title>
                        <a href={`${botReward?.chainId ? RPC_URLS[botReward.chainId].explorer : ""}/token/${botReward?.currency}#code`} className="text-sm" target="_blank" rel="noreferrer">{botReward?.currencySymbol}</a>
                    </div>
                </>
            ),
            action: (
                <>
                    <div className="ant-action">
                        <Button.Group>
                            { user && <Button disabled={user.role !== "super"} onClick={() => onDeleteDeveloper(item)}>Delete</Button> }
                        </Button.Group>
                    </div>
                </>
            )
        };
    });

    const [selectedDeveloper, setSelectedDeveloper] = useState(null);
    const [newDeveloperForm] = Form.useForm();
    const [isOpenNewDeveloper, setIsOpenNewDeveloper] = useState(false);
    const [isOpenDeleteDeveloper, setIsOpenDeleteDeveloper] = useState(false);

    const onDeleteDeveloper = (item) => {
        setSelectedDeveloper(item);
        setIsOpenDeleteDeveloper(true);
    }

    const showNewDeveloperModal = () => {
        setIsOpenNewDeveloper(true);
    };

    const onHandleAddDeveloper = async () => {
        const valid = await newDeveloperForm.validateFields();
        if (valid.errorFields)
            return;
        
        const formData = new FormData();
        formData.set("name", newDeveloperForm.getFieldValue("i_name"));
        formData.set("email", newDeveloperForm.getFieldValue("i_email"));
        formData.set("wallet", newDeveloperForm.getFieldValue("i_wallet"));
        dispatch(registerDeveloper(formData));
        setIsOpenNewDeveloper(false);
    };

    const onCancelAddDeveloper = () => {
        setIsOpenNewDeveloper(false);
    };

    const onHandleDeleteDeveloper = () => {
        if (selectedDeveloper) {
            dispatch(deleteDeveloper(selectedDeveloper._id));
            setSelectedDeveloper(null);
        }
        setIsOpenDeleteDeveloper(false);
    };

    const onCancelDeleteDeveloper = () => {
        setIsOpenDeleteDeveloper(false);
    }

    const [ monthlyProfit, setMonthlyProfit ] = useState(0);
    const [ totalProfit, setTotalProfit ] = useState(0);
    const [ isOpenWithdraw, setIsOpenWithdraw ] = useState(false);
    const [ isOpenWithdrawAll, setIsOpenWithdrawAll ] = useState(false);
    const [ selectedBot, setSelectedBot ] = useState(null);

    const onWithdraw = (botId) => {
        setSelectedBot(bots?.find(bot => bot._id === botId));
        setIsOpenWithdraw(true);
    };

    const onWithdrawAll = () => {
        setIsOpenWithdrawAll(true);
    }

    const onHandleWithdraw = () => {
        if (selectedBot) {
            dispatch(withdrawFromBot(selectedBot._id));
            setSelectedBot(null);
        }
        setIsOpenWithdraw(false);
    }

    const onCancelWithdraw = () => {
        setSelectedBot(null);
        setIsOpenWithdraw(false);
    }

    const onHandleWithdrawAll = () => {
        dispatch(withdrawFromAllBots());
        setIsOpenWithdrawAll(false);
    }

    const onCancelWithdrawAll = () => {
        setIsOpenWithdrawAll(false);
    }

    useEffect(() => {
        const now = new Date();
        const from = new Date(now.getFullYear(), now.getMonth());
        const to = (from.getMonth() === 11) ? new Date(from.getFullYear() + 1, 0): new Date(from.getFullYear(), from.getMonth() + 1);
        let total = 0;
        let monthly = 0;
        for (let i = 0; i < trades?.length; i++) {
            const d = new Date(trades[i].executedAt);
            total += trades[i].profit;
            if (from <= d && d < to)
                monthly += trades[i].profit;
        }
        setMonthlyProfit(Number(monthly).toFixed(2));
        setTotalProfit(Number(total).toFixed(2));
    }, [trades]);

    return (
        <>
            <Row gutter={[24, 0]}>
                <Col xs={24} md={16}>
                    <Row gutter={[24, 0]}>
                        <Col xs={24} xl={12} className="mb-24">
                            <Card
                                title={wifi}
                                bordered={false}
                                className="card-credit header-solid h-full">
                                <Link to="/finance/wallet">
                                    <h5 className="card-number">Address: { walletAddress }</h5>
                                    <h5 className="card-number">Balance: { adminWallet ? `${adminWallet.ETH ? adminWallet.ETH : 0}ETH / ${adminWallet.BNB ? adminWallet.BNB : 0}BNB` : 0 }</h5>
                                </Link>
                                <div className="card-footer">
                                    <div className="card-footer-col col-logo ml-auto">
                                        <img src={mastercard} alt="mastercard" />
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col xs={12} xl={6} className="mb-24">
                            <Card bordered={false} className="widget-2 h-full">
                                <Statistic title={
                                        <>
                                            <div className="icon">{angle}</div>
                                            <h6>Monthly Profit</h6>
                                        </>
                                    }
                                    value={`${monthlyProfit}`}
                                    prefix={<PlusOutlined />} />
                            </Card>
                        </Col>
                        <Col xs={12} xl={6} className="mb-24">
                            <Card bordered={false} className="widget-2 h-full">
                                <Statistic title={
                                        <>
                                            <div className="icon">
                                                {angle}
                                            </div>
                                            <h6>Total Profit</h6>
                                        </>
                                    }
                                    value={`${totalProfit}`}
                                    prefix={<PlusOutlined />} />
                            </Card>
                        </Col>
                        <Col xs={24} className="mb-24">
                            <Card
                                className="header-solid h-full ant-card-p-0"
                                title={
                                    <>
                                        <Row gutter={[24, 0]} className="ant-row-flex ant-row-flex-middle">
                                            <Col xs={24} md={12}>
                                                <h6 className="font-semibold m-0">Bot Balances</h6>
                                            </Col>
                                            <Col xs={24} md={12} className="d-flex">
                                                <Button type="primary" onClick={onWithdrawAll}>Withdraw All</Button>
                                            </Col>
                                        </Row>
                                    </>
                                }>
                                <div className="table-responsive">
                                    <Table
                                        columns={botBalanceTableFields}
                                        dataSource={botBalanceTableData}
                                        pagination={false}
                                        className="ant-border-space" />
                                </div>
                                <Modal title="Withdraw from Bot" open={isOpenWithdraw} onOk={onHandleWithdraw} onCancel={onCancelWithdraw}>
                                    <p>Are you sure you want to withdraw from bot '{selectedBot ? selectedBot.name : ''}'?</p>
                                </Modal>
                                <Modal title="Withdraw from All Bots" open={isOpenWithdrawAll} onOk={onHandleWithdrawAll} onCancel={onCancelWithdrawAll}>
                                    <p>Are you sure you want to withdraw from all bots?</p>
                                </Modal>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col span={24} md={8} className="mb-24">
                    <Card
                        bordered={false}
                        className="header-solid h-full ant-invoice-card"
                        title={[<h6 className="font-semibold m-0">Rewards</h6>]}>
                        <div className="table-responsive">
                            <Table
                                columns={rewardTableFields}
                                dataSource={userRewardTableData}
                                pagination={true}
                                className="ant-border-space" />
                        </div>
                    </Card>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={24} md={24} className="mb-24">
                    <Card
                        className="header-solid h-full"
                        bordered={false}
                        title={[<h6 className="font-semibold m-0">User Financial Activities</h6>]}
                        bodyStyle={{ paddingTop: "0" }}>
                        <div className="table-responsive">
                            <Table
                                columns={userTransactionTableFields}
                                dataSource={userTransactionTableData}
                                pagination={true}
                                className="ant-border-space" />
                        </div>
                    </Card>
                </Col>
                <Col span={24} md={24} className="mb-24">
                    <Card
                        bordered={false}
                        bodyStyle={{ paddingTop: 0 }}
                        className="header-solid h-full  ant-list-yes"
                        title={<h6 className="font-semibold m-0">Admin Activities</h6>}>
                        <div className="table-responsive">
                            <Table
                                columns={botTransactionTableFields}
                                dataSource={botTransactionTableData}
                                pagination={true}
                                className="ant-border-space" />
                        </div>
                    </Card>
                </Col>
                <Col span={24} md={24} className="mb-24">
                    <Card
                        bordered={false}
                        bodyStyle={{ paddingTop: 0 }}
                        className="header-solid h-full  ant-list-yes"
                        title={<h6 className="font-semibold m-0">Licenses</h6>}>
                        <div className="table-responsive">
                            <Table
                                columns={licenseTableFields}
                                dataSource={licenseTableData}
                                pagination={true}
                                className="ant-border-space" />
                        </div>
                    </Card>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col xs={24} xl={24}>
                    <Card
                        bordered={false}
                        className="criclebox tablespace mb-24"
                        title="Developer List">
                        <div className="table-responsive">
                            <Table
                                columns={developerTableFields}
                                dataSource={developerTableData}
                                pagination={false}
                                className="ant-border-space" />
                        </div>
                        <div className="uploadfile pb-15 shadow-none">
                            <Button
                                type="dashed"
                                className="ant-full-box"
                                disabled={user && user.role !== "super"}
                                icon={<ToTopOutlined />}
                                onClick={showNewDeveloperModal}>
                                Add New Developer...
                            </Button>
                        </div>
                        <Modal title="Delete Developer" open={isOpenDeleteDeveloper} onOk={onHandleDeleteDeveloper} onCancel={onCancelDeleteDeveloper}>
                            <p>Are you sure you want to delete admin '{selectedDeveloper ? selectedDeveloper.name : ''}'?</p>
                        </Modal>
                        <Modal title="Add New Developer" open={isOpenNewDeveloper} onOk={onHandleAddDeveloper} onCancel={onCancelAddDeveloper}>
                            <Form
                                form={newDeveloperForm}
                                layout="vertical"
                                className="row-col">
                                <Form.Item
                                    className="username"
                                    label="Name"
                                    name="i_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input user name!",
                                        },
                                    ]}>
                                    <Input placeholder="Type your user name" />
                                </Form.Item>
                                <Form.Item
                                    className="username"
                                    label="Email"
                                    name="i_email"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input e-mail!",
                                        },
                                    ]}>
                                    <Input placeholder="Type your e-mail" />
                                </Form.Item>
                                <Form.Item
                                    className="username"
                                    label="Wallet"
                                    name="i_wallet"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input wallet!",
                                        },
                                    ]}>
                                    <Input placeholder="Type your wallet" />
                                </Form.Item>
                            </Form>
                        </Modal>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default Finance;
