import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    Row,
    Col,
    Card,
    Table,
    Typography,
    Modal,
    Button,
    Input,
} from "antd";
import { getAllFees, deleteFee, getAllPortions, updatePortion, deletePortion, updateFee } from "../actions/policyAction";

const { Title } = Typography;

const portionTableFields = [
    {
        title: "CATEGORY",
        dataIndex: "category",
        key: "category",
        width: "32%",
    },
    {
        title: "PORTION",
        dataIndex: "portion",
        key: "portion",
        width: "30%",
    },
    {
        title: "ACTION",
        key: "action",
        dataIndex: "action",
    },
];

const feeTableFields = [
    {
        title: "NAME",
        dataIndex: "name",
        key: "name",
        width: "32%",
    },
    {
        title: "FEE",
        dataIndex: "fee",
        key: "fee",
        width: "30%",
    },
    {
        title: "ACTION",
        key: "action",
        dataIndex: "action",
    },
];

function Policy() {
    const dispatch = useDispatch();
    const { portions, fees } = useSelector(state => state.policy);
    const [ selectedPortion, setSelectedPortion ] = useState(null);
    const [ isOpenDeletePortion, setIsOpenDeletePortion ] = useState(false);
    const [ isEditPortion, setIsEditPortion ] = useState(false);
    const [ editPortionValue, setEditPortionValue ] = useState(0);
    const [ selectedFee, setSelectedFee ] = useState(null);
    const [ isOpenDeleteFee, setIsOpenDeleteFee ] = useState(false);
    const [ isEditFee, setIsEditFee ] = useState(false);
    const [ editFeeValue, setEditFeeValue ] = useState(0);
    
    useEffect(() => {
        dispatch(getAllPortions());
        dispatch(getAllFees());
    }, [dispatch]);

    const onEditPortion = (portionId) => {
        const portion = portions?.find(item => item._id === portionId);
        setSelectedPortion(portion);
        setEditPortionValue(portion?.value);
        setIsEditPortion(true);
    };

    const onChangePortion = (ev) => {
        ev.preventDefault();
        setEditPortionValue(ev.target.value);
    }

    const onSavePortion = () => {
        if (selectedPortion) {
            dispatch(updatePortion(selectedPortion._id, { value: editPortionValue }));
            setSelectedPortion(null);
        }
        setIsEditPortion(false);
    }

    const onCancelPortion = () => {
        setIsEditPortion(false);
    }

    const onDeletePortion = (portionId) => {
        const portion = portions?.find(item => item._id === portionId);
        setSelectedPortion(portion);
        setIsOpenDeletePortion(true);
    };

    const onHandleDeletePortion = () => {
        if (selectedPortion) {
            dispatch(deletePortion(selectedPortion._id));
            setSelectedPortion(null);
        }
        setIsOpenDeletePortion(false);
    }

    const onCancelDeletePortion = () => {
        setIsOpenDeletePortion(false);
    }

    const onEditFee = (feeId) => {
        const fee = fees?.find(item => item._id === feeId);
        setSelectedFee(fee);
        setEditFeeValue(fee?.value);
        setIsEditFee(true);
    };

    const onChangeFee = (ev) => {
        ev.preventDefault();
        setEditFeeValue(ev.target.value);
    }

    const onSaveFee = () => {
        if (selectedFee) {
            dispatch(updateFee(selectedFee._id, { value: editFeeValue }));
            setSelectedFee(null);
        }
        setIsEditFee(false);
    }

    const onCancelFee = () => {
        setIsEditFee(false);
    }

    const onDeleteFee = (feeId) => {
        const fee = fees?.find(item => item._id === feeId);
        setSelectedFee(fee);
        setIsOpenDeleteFee(true);
    };

    const onHandleDeleteFee = () => {
        if (selectedFee) {
            dispatch(deleteFee(selectedFee._id));
            setSelectedFee(null);
        }
        setIsOpenDeleteFee(false);
    }

    const onCancelDeleteFee = () => {
        setIsOpenDeleteFee(false);
    }

    const portionTableData = portions?.map((item, index) => {
        return {
            key: index,
            category: (
                <>
                    <div className="author-role">
                        <Title level={5}>{item.category === "user" ? "User" : "Developer"}</Title>
                    </div>
                </>
            ),
            portion: (
                <>
                    <div className="author-role">
                        {
                            (isEditPortion && selectedPortion?._id === item._id) ?
                            (
                                <Input placeholder="Input portion value with percent" value={editPortionValue} onChange={onChangePortion} />
                            ) :
                            (
                                <Title level={5}>{item.value}%</Title>
                            )
                        }
                    </div>
                </>
            ),
            action: (
                <>
                    <div className="ant-action">
                        {
                            (isEditPortion && selectedPortion?._id === item._id) ?
                            (
                                <Button.Group>
                                    <Button onClick={onSavePortion}>Save</Button>
                                    <Button onClick={onCancelPortion}>Cancel</Button>
                                </Button.Group>
                            ) :
                            (
                                <Button.Group>
                                    <Button onClick={() => onEditPortion(item._id)}>Edit</Button>
                                    <Button onClick={() => onDeletePortion(item._id)}>Delete</Button>
                                </Button.Group>
                            )
                        }
                    </div>
                </>
            )
        };
    });

    const feeTableData = fees.map((item, index) => {
        return {
            key: index,
            name: (
                <>
                    <div className="author-role">
                        <Title level={5}>{item.category === "withdraw" ? "Withdrawal" : "Unknown"}</Title>
                    </div>
                </>
            ),
            fee: (
                <>
                    <div className="author-role">
                        {
                            (isEditFee && selectedFee._id === item._id) ?
                            (
                                <Input placeholder="Input fee value" value={editFeeValue} onChange={onChangeFee} />
                            ) :
                            (
                                <Title level={5}>{item.value}{item.unit === "percent" ? "%" : "$"}</Title>
                            )
                        }
                    </div>
                </>
            ),
            action: (
                <>
                    <div className="ant-action">
                        {
                            (isEditFee && selectedFee._id === item._id) ?
                            (
                                <Button.Group>
                                    <Button onClick={onSaveFee}>Save</Button>
                                    <Button onClick={onCancelFee}>Cancel</Button>
                                </Button.Group>
                            ) :
                            (
                                <Button.Group>
                                    <Button onClick={() => onEditFee(item._id)}>Edit</Button>
                                    <Button onClick={() => onDeleteFee(item._id)}>Delete</Button>
                                </Button.Group>
                            )
                        }
                    </div>
                </>
            )
        };
    });
    
    return (
        <>
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs={24} xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            title="Portion of Profit">
                            <div className="table-responsive">
                                <Table
                                    columns={portionTableFields}
                                    dataSource={portionTableData}
                                    pagination={false}
                                    className="ant-border-space" />
                            </div>
                        </Card>
                    </Col>
                    <Col xs={24} xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            title="Service Fee">
                            <div className="table-responsive">
                                <Table
                                    columns={feeTableFields}
                                    dataSource={feeTableData}
                                    pagination={false}
                                    className="ant-border-space" />
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Modal title="Delete Portion" open={isOpenDeletePortion} onOk={onHandleDeletePortion} onCancel={onCancelDeletePortion}>
                    <p>Are you sure you want to delete portion '{selectedPortion ? (selectedPortion.category === "user" ? "User" : "Developer") : ''}'?</p>
                </Modal>
                <Modal title="Delete Fee" open={isOpenDeleteFee} onOk={onHandleDeleteFee} onCancel={onCancelDeleteFee}>
                    <p>Are you sure you want to delete fee '{selectedFee ? (selectedFee.category === "withdraw" ? "Withdrawal" : "Unknown") : ''}'?</p>
                </Modal>
            </div>
        </>
    );
}

export default Policy;
