import { Switch, Route } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Layout,
    Row,
    Col,
    Spin,
} from 'antd';
import Main from "./components/layout/Main";
import Home from "./pages/Home";
import Bots from "./pages/Bots";
import Users from "./pages/Users";
import Finance from "./pages/Finance";
import Notification from "./pages/Notification";
import Policy from "./pages/Policy";
import Messages from "./pages/Messages";
import Profile from "./pages/Profile";
import WalletDetail from "./pages/WalletDetail";
import UserDetail from "./pages/UserDetail";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import ProtectedRoute from "./routes/ProtectedRoute";
import { loadUser } from "./actions/userAction";

import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";

import logo from "./assets/images/logo.png";

const { Header, Content } = Layout;

function App() {
    const dispatch = useDispatch();
    const { loading } = useSelector(state => state.user);
    useEffect(() => {
        dispatch(loadUser());
    }, [dispatch]);

    window.addEventListener("contextmenu", (e) => e.preventDefault());
    window.addEventListener("keydown", (e) => {
        if (e.keyCode === 123)
            e.preventDefault();
        if (e.ctrlKey && e.shiftKey && e.keyCode === 73)
            e.preventDefault();
        if (e.ctrlKey && e.shiftKey && e.keyCode === 74)
            e.preventDefault();
    });

    return (
        <div className="App">
            <Switch>
                <Route exact path="/sign-in" component={SignIn} />
                <Route exact path="/sign-up" component={SignUp} />
                <ProtectedRoute>
                    {
                        loading ?
                        (<Layout className="layout-default layout-signin">
                            <Header>
                                <div className="header-col header-brand">
                                    <img src={logo} alt="" style={{height: '40px', width: 'auto'}}/>
                                    &nbsp;&nbsp;
                                    <span>High Perform Technology</span>
                                </div>
                            </Header>
                            <Content className="signin">
                                <Row gutter={[24, 0]} justify="space-around">
                                    <Col
                                        xs={{ span: 24, offset: 0 }}
                                        lg={{ span: 6, offset: 2 }}
                                        md={{ span: 12 }}>
                                        <Spin tip="Loading" size="large" style={{ margin: "120px 0px" }}>
                                            <div className="content" />
                                        </Spin>
                                    </Col>
                                </Row>
                            </Content>
                        </Layout>)
                        :
                        (<Main>
                            <Route exact path="/dashboard" component={Home} />
                            <Route exact path="/bots" component={Bots} />
                            <Route exact path="/users" component={Users} />
                            <Route exact path="/finance" component={Finance} />
                            <Route exact path="/finance/wallet" component={WalletDetail} />
                            <Route path="/finance/user-reward/:userId" component={UserDetail} />
                            <Route exact path="/notification" component={Notification} />
                            <Route exact path="/policy" component={Policy} />
                            <Route exact path="/messages" component={Messages} />
                            <Route exact path="/profile" component={Profile} />
                        </Main>)
                    }
                </ProtectedRoute>
            </Switch>
        </div>
    );
}

export default App;
