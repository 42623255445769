import axios from 'axios';
import {
    NOTIFICATIONS_SUCCESS,
    NOTIFICATIONS_FAIL,
    NOTIFICATION_ADD_SUCCESS,
    NOTIFICATION_ADD_FAIL,
 } from "../constants/notificationConstants";
import { getServerURL } from '../constants/server';

export const getAllNotifications = () => async (dispatch) => {
    try {
        const { data } = await axios.get(`${getServerURL()}/api/v1/notifications/all`, {
            headers: {
                'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        });
        dispatch({
            type: NOTIFICATIONS_SUCCESS,
            payload: data.notifications,
        });
    }
    catch (error) {
        console.log(error);
        dispatch({
            type: NOTIFICATIONS_FAIL,
            payload: "Failed to get all notifications",
        });
    }
};

export const addNotification = (title, category, description, recipient) => async (dispatch) => {
    try {
        const { data } = await axios.post(`${getServerURL()}/api/v1/notifications/add`, 
            { title, category, description, recipient },
            { headers: { 'Authorization': `bearer ${localStorage.getItem('token')}` } });
        dispatch({
            type: NOTIFICATION_ADD_SUCCESS,
            payload: data.notification,
        });
    }
    catch (error) {
        console.log(error);
        dispatch({
            type: NOTIFICATION_ADD_FAIL,
            payload: "Failed to add new bot",
        });
    }
}